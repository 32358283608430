<template>
    <div>Redirect in progress</div>
</template>
<script>
    export default {
        name: "HelpComponent",
        created() {
            open("https://planejadordetreino.com/video/", "_blank")
            history.back()
        }
    }
</script>
