<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <select-esporte-component 
            v-if="onSelect" 
            @select="selectEsporte"
            :show="true"
            @hide="() => { this.onSelect = false}"
        ></select-esporte-component>

        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('exercices.videos') }}</h3>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <button class="btn btn-flat btn-warning" @click="() => { this.onSelect = true;}">
                    <i class="fa fa-plus"></i> {{ t('datatable.add') }}
                </button>
                <data-table v-if="exercicios" class="table table-striped table-bordered" :data="exercicios" :columns="columns"
                    :options="{responsive: true, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th>{{ t('general.actions') }}</th>
                            <th>{{ t('treinos.clube') }}</th>
                            <th>{{ t('treinos.category') }}</th>
                            <th>{{ t('treinos.description') }}</th>
                            <th>{{ t('treinos.prof') }}</th>
                            <th>{{ t('treinos.date') }}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>

        <!-- Modals dos exercicios -->
        <modal-component :classList="['col-10', 'col-sm-7 col-md-5']" v-if="selected" :title="selected.clube"
            @hide="() => {this.selected = null}"
            :show="true"
        >
            <form ref="form" @submit.prevent="update" class="p-1 col-12 m-0">
                <div class="p-1 form-group m-0 p-2 col-12 position-relative">
                    <input type="file" name="img" class="d-none" ref="file" @change="changeFile">
                    <img :src="selected.img_url" class="col-11 p-1 m-auto">
                    <button class="btn btn-warning position-absolute bottom-0 end-0" type="button" @click="openFile">
                        <i class="fa fa-upload"></i>
                    </button>
                </div>
                <div class="p-1 form-group m-0 p-2 col-12" v-for="input in inputs" :key="input.model">
                    <span class="m-0 col-12 p-0 py-1 row justify-content-between" v-if="input.header">
                        <input :id="['label_' + input.model]" class="editable col-10" v-model="selected['h_' + input.model]" contenteditable>
                        <i class="fa fa-edit mx-2" style="cursor: pointer" @click="edit('label_'+input.model)"></i>
                    </span>
                    <span class="m-0" v-else>{{ input.label }}</span>
                    <input :type="input.type" :name="input.model" v-model="selected[input.model]" class="form-control" required>
                </div>

                <div class="p-1 form-group m-0 p-2 col-12">
                    <span for="descricao">{{ t('exercices.description') }}</span>
                    <textarea class="form-control" rows="4" maxlength="800" required v-model="selected.descricao"></textarea>
                </div>

                <div class="p-1 form-group m-0 py-2">
                    <button type="submit" class="btn btn-primary">{{ t('general.update_data') }}</button>
                </div>
            </form>
        </modal-component>
        <!-- ./Modals dos exercicios -->

        <modal-component :classList="['col-12 col-md-11 col-lg-10 m-auto', 'p-1']" v-if="view"
            @hide="() => {this.view = null}"
            :show="true"
        >
            <div class="row p-1 bg-dark m-0 mx-auto flex-nowrap" style="overflow-x: auto" ref="fs">
                <div class="col-auto p-1" style="max-width: 30% !important">
                    <div class="col-12 position-relative">
                        <i class="fas fa-expand-arrows-alt position-absolute toggle-fs text-light" @click="fullScreen" title="FullScreen mode"></i>
                        <img :src="view.img_url" class="p-0 m-0" style="max-width: 100px;">
                    </div>
                    <div class="col-12" v-for="label of labels" :key="label.key">
                        <span style="text-transform: uppercase;" class="text-bold d-block">{{ label.label }}:</span>
                        <span class="d-block">{{ view[label.model] }}</span>
                    </div>
                    <div class="col-12">
                        <span style="text-transform: uppercase;" class="text-bold d-block">{{ t('treinos.description') }}:</span>
                        <span class="d-block">
                            <pre>{{ decodeEntities(view['descricao']) }}</pre>
                        </span>
                    </div>
                </div>
                <img :src="view.gif" v-if="view.gif" class="p-0 m-0 video-play" ref="video">
                <video :src="view.video" v-else class="p-0 m-0 video-play" ref="_video" muted autoplay loop>
                    Video not supported, try to download it
                </video>
            </div>
        </modal-component>
        
        <!-- Modal exportar para clube -->
        <modal-component :classList="['col-10 col-md-9 col-lg-6 m-auto', 'p-1']" v-if="send"
            @hide="() => {this.send = null}"
            :show="true"
            :title="t('teams.export')"
        >
            <div class="col-12 p-3">
                <label>{{ t('teams.team') }}</label>
                <select class="form-control select2" required v-model="team">
                    <option :value="-1">-----</option>
                    <option v-for="(t, i) in teams" :key="t.id" :value="i">
                        {{ t.name }}
                    </option>
                </select>
            </div>
            <div class="col-12 p-3" v-if="team >= 0">
                <label>{{ t('teams.category') }}</label>
                <select class="form-control select2" required v-model="category_id">
                    <option v-for="category in actualTeam.categories" :key="category.id" :value="category.id">
                        {{ category.name }}
                    </option>
                </select>
            </div>

            <div class="col-12 p-3" v-if="category_id > 0 && team >= 0">
                <label>{{ t('treinos.date') }}</label>
                <input type="date" class="form-control" name="data" v-model="date" />
            </div>

            <div class="col-12 p-3" v-if="team >= 0 && category_id > 0 && date">
                <button class="btn btn-primary" @click="sendToClube()">{{ t('teams.ex') }}</button>
            </div>
        </modal-component>
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import SelectEsporteComponent from '@/components/SelectEsporteComponent.vue'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { t } from '@/lang'
    import { decodeEntities, getExImage, deleteExercice, fillData } from '@/utilities/HelpFunctions.js'

    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "FormacoesView",
        components: {
            DataTable,
            ModalComponent,
            SelectEsporteComponent
        },
        data() {
            return {
                exercicios: null,
                showModal: false,
                playNow: false,
                onSelect: false,
                category_id: null,
                team: -1,
                date: null,
                send: null,
                teams: [],
                columns: [
                    {data: 'id'},
                    {data: null, render: data => `
                        <div class="row p-0 m-0 flex-nowrap">
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-primary' data-id='${data.id}' data-action='view'>
                                    <i class='fa fa-play' data-id='${data.id}' data-action='view'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-warning' data-id='${data.id}' data-action='edit'>
                                    <i class='fa fa-edit' data-id='${data.id}' data-action='edit'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-danger' data-id='${data.id}' data-action='delete'>
                                    <i class='fa fa-trash' data-id='${data.id}' data-action='delete'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-success' data-id='${data.id}' data-action='download'>
                                    <i class='fa fa-download' data-id='${data.id}' data-action='download'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline' title='Exportar para clube'>
                                <button class='btn btn-flat btn-success' data-id='${data.id}' data-action='send'>
                                    <i class='fa fa-share' data-id='${data.id}' data-action='send'></i>
                                </button>    
                            </div>
                        </div>`
                    },
                    {data: 'clube'},
                    {data: 'categoria'},
                    {data: null, render: data => `<span class='no-br'>${data.descricao.substr(0, 35)}<i class='d-none'>${data.descricao.substr(35, data.descricao.length)}</i></span>`},
                    {data: 'professor'},
                    {data: 'data'},
                ],
                inputs: [
                    {label: t('treinos.clube'), type: "text", model: 'clube'},
                    {label: t('treinos.coach'), type: "text", model: 'professor', header: true},
                    {label: t('treinos.category'), type: "text", model: 'categoria', header: true},
                    {label: t('treinos.date'), type: "date", model: 'data', header: true},
                    {label: t('treinos.local'), type: "text", model: 'local', header: true}
                ],
                labels: [
                    {key: 'professor', model: 'professor', label: t('treinos.prof') },
                    {key: 'clube', model: 'clube', label: t('treinos.clube') },
                    {key: 'categoria', model: 'categoria', label: t('treinos.category') },
                    {key: 'data', model: 'data', label: t('treinos.date') },
                    {key: 'local', model: 'local', label: t('treinos.local') },
                    // {key: 'descricao', model: 'descricao', label: t('treinos.description') },
                ],
                selected: null,
                view: null,
                esporte: null
            }
        },
        created() {
            document.title = "Meus Videos"
            this.getExercices()
            this.getUser()
        },
        
        computed: {
            ...mapState(['loading']),
            actualTeam() {
                return this.teams[this.team]
            }
        },
        methods: {
            decodeEntities,
            ...mapMutations(['SET_LOADING']),
            ...mapActions(['get', 'put', 'delete', 'post']),
            async getExercices() {
                this.SET_LOADING(true)
                const response = await this.get({
                    url: 'formactions?type=video'
                })
                
                if (response.status != 200) {
                    alert(response.data.message)
                    this.$router.push("/videos")
                } else {
                    this.exercicios = response.data.formacoes
                }
                this.SET_LOADING(false)
                if (!this.loading && this.$el.querySelector('#my-table')) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }
            },
            async getUser() {
                this.SET_LOADING(true)
                
                const response = await this.get({url: 'user'})
                if (response.status == 200) {
                    this.teams = response.data.user.teams
                } else if (response.status == 401) {
                    this.SET_USER(null)
                    this.$router.push('/login')
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)
            },
            async handleClick(event) {
                if (event.target.hasAttribute("data-action")) {
                    const excludes = ['category', 'category_id']
                    const defaults = {
                        h_categoria: t('treinos.category'),
                        h_data: t('treinos.date'),
                        h_professor: t('treinos.coach'),
                        h_local: t('treinos.local')
                    }
                    let [exercice] = this.exercicios.filter(ex => ex.id == event.target.getAttribute("data-id"))
                    switch(event.target.getAttribute("data-action")) {
                        case 'view':
                            this.SET_LOADING(true)
                            this.view = Object.assign(exercice, {
                                gif: null,
                                video: null
                            })
                            // formactionlogo
                            // await this.get({url: 'formactionlogo/' + exercice.id, type: 'blob'})
                            this.view.img_url = await getExImage(exercice.id, this.get, 'formactionlogo/', 'image/png')
                            
                            // eslint-disable-next-line no-case-declarations
                            const fileType = /(\.gif)$/.exec(this.view.file) ? "image/gif":"video/webm"
                            // eslint-disable-next-line no-case-declarations
                            const rs = await getExImage(exercice.id, this.get, 'formactionimg/', fileType)
                            
                            if(fileType != "image/gif") {
                                this.view.gif = null
                                this.view.video = rs
                            } else {
                                this.view.video = null
                                this.view.gif = rs
                            }
                            this.SET_LOADING(false)
                        break
                        case 'edit':
                            this.SET_LOADING(true)
                            // decode entities
                            exercice = Object.assign({}, exercice)
                            for(const key in exercice) {
                                if (!excludes.includes(key)) {
                                    if (key.startsWith('h_')) {
                                        if (!exercice[key] || exercice[key].length <= 0) {
                                            exercice[key] = defaults[key]
                                        }
                                    }
                                    exercice[key] = decodeEntities(exercice[key])
                                }
                            }
                            this.selected = exercice
                            this.selected.img_url = await getExImage(exercice.id, this.get, 'formactionlogo/')
                            this.SET_LOADING(false)
                        break
                        case 'delete':
                            // confirm delete
                            if (confirm(t('general.do-you-want'))) {
                                this.SET_LOADING(true)
                                await deleteExercice(exercice.id, this.delete, 'formactions')
                                await this.getExercices()
                                this.SET_LOADING(false)
                            }
                        break
                        case 'download':
                            this.download(exercice)
                        break
                        case 'send':
                            this.send = exercice
                        break
                    }
                }
            },
            async download(exercice) {
                // eslint-disable-next-line no-case-declarations
                const fileType = /(\.gif)$/.exec(exercice.file) ? "image/gif":"video/webm"
                this.SET_LOADING(true)
                const url = await getExImage(exercice.id, this.get, 'formactionimg/', fileType)
                
                const a = document.createElement("a")
                a.setAttribute( "download", exercice.professor + "-" + exercice.clube + (fileType == "image/gif" ? ".gif":".webm") )
                a.setAttribute("href", url)
                a.setAttribute("target", "_blanck")

                a.click()
                this.SET_LOADING()
            },
            async sendToClube() {
                console.log(this.category_id, this.send.id)
                this.SET_LOADING(true)
                const res = await this.post({url: "teamtreinos", data: {
                    category_id: this.category_id,
                    data: this.date,
                    id: this.send.id,
                    type: 'video'
                }})

                if (res.status >= 200 && res.status < 300) {
                    alert(t('general.done'))
                    this.send = null
                } else {
                    alert(res.data.message)
                    console.log(res.data)
                }
                this.SET_LOADING(false)
            },
            selectEsporte(name) {
                this.$router.push(`/select?esporte=${name}&video=true`)
            },
            selectCampo(id) {
                this.$router.push(`/marcar?esporte=${this.esporte}&camp=${id + 1}&video=true`)
            },
            t,
            async update({target}) {
                if(target.checkValidity()) {
                    this.SET_LOADING(true)
                    const f = new FormData()
                    f.append("img", this.selected.img)
                    delete this.selected.img
                    if (!fillData(f, this.selected)) {
                        alert(t("exercices.fill-error"))
                        this.SET_LOADING(false)
                        return null
                    }
                    const response = await this.post({
                        url: 'formactions/' + this.selected.id,
                        data: f,
                        FormData: true
                    })
                    if (response.status == 200 || response.status == 204) {
                        alert(t('general.updated'))
                        this.getExercices()
                    } else {
                        alert(response.data.message)
                    }
                    this.selected = null
                    this.SET_LOADING(false)
                }
            },
            fullScreen() {
                try {
                    if (document.body.requestFullscreen) {
                        if (document.fullscreenElement || document.webkitFullscreenElement) {
                            document.exitFullscreen();
                        } else {
                            this.$refs.fs.requestFullscreen()
                        }
                    } else if (document.body.webkitRequestFullscreen) {
                        if (document.fullscreenElement || document.webkitFullscreenElement) {
                            document.webkitExitFullscreen();
                        } else {
                            this.$refs.fs.webkitRequestFullscreen()
                        }
                    }
                } catch (e) {
                    console.log(e)
                }
            },
            openFile() {
                this.$refs.file.click()
            },
            changeFile(evt) {
                const [img] = evt.target.files
                if (img) {
                    this.selected.img_url = URL.createObjectURL(img)
                    this.selected.img = img
                }
            },
            edit(id) {
                this.$el.querySelector(`#${id}`).focus()
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
    .upload-btn {
        bottom: 0px;
        right: 0px;
    }
    .video-play {
        width: 630px;
        height: 472.5px;
    }
    .toggle-fs {
        cursor: pointer;
        right: 0.3rem;
        top: 0.3rem;
    }
    pre {
        font-family: inherit !important;
        font-size: inherit;
        line-height: 1;
        margin: 0px;
        color: inherit;
        padding: 0px;
        border: none;
        white-space: pre-line;
        text-align: justify;
        max-height: 100% !important;
        overflow: hidden;
    }
    .no-br{
        line-break: loose;
    }
</style>
