<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('teams.users') }}</h3>
            </div>
            <div class="p-1">
                <button class="btn btn-warning btn-flat" @click="() => this.show = true">
                    <i class="fa fa-plus"></i> {{ t("datatable.add") }}
                </button>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <data-table v-if="team" class="table table-striped table-bordered" :data="team.users" :columns="columns"
                    :options="{responsive: true, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th>{{ t('auth.signup.name') }}</th>
                            <th>{{ t('auth.login.email') }}</th>
                            <th>{{ t('teams.remove') }}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>

        <!-- Modals do plano -->
        <modal-component :classList="['col-10', 'col-sm-8', 'col-md-7', 'col-lg-5', 'col-xlg-5']" v-if="show" :title="t('teams.add')"
            @hide="() => {this.show = false}"
            :show="true"
        >
            <form ref="form" @submit.prevent="addOrRemove" class="row p-1 col-12">
                <div class="p-1 form-group m-0 col-12">
                    <label>{{ t('teams.email')}}</label>
                    <input type="email" required name="email" v-model="email" class="form-control" />
                </div>

                <div class="p-1">
                    <button class="btn btn-primary">
                        {{ t('datatable.add') }}
                    </button>
                </div>
            </form>
        </modal-component>
        <!-- ./Modals do plano -->
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { t } from '@/lang'
    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "PlansView",
        components: {
            DataTable,
            ModalComponent,
        },
        data() {
            return {
                team: null,
                email: '',
                show: false,
                columns: [
                    {data: 'id'},
                    {data: 'name'},
                    {data: 'email'},
                    {data: null, render: data => `
                        <span class='p-1'>
                            <button class='btn btn-danger btn-flat' data-id='${data.email}' data-action='delete'>
                                <i class='fa fa-trash' data-id='${data.email}' data-action='delete'></i>
                            </button>
                        </span>
                    `}
                ],
            }
        },
        async created() {
            document.title = t('teams.users')
            await this.getTeam()
        },
        
        computed: {
            ...mapState(['loading'])
        },
        methods: {
            ...mapActions(['get', 'post', 'put', 'delete']),
            ...mapMutations(['SET_LOADING']),
            async handleClick(event) {
                if (event.target.hasAttribute('data-action')) {
                    
                    const planID = event.target.getAttribute('data-id')
                    switch(event.target.getAttribute('data-action')) {
                        case 'delete':
                            if (confirm(t('general.do-you-want'))) {
                                await this.deleteUser(planID)
                            }
                            break
                    }
                }
            },
            async deleteUser(id) {
                this.SET_LOADING(true)
                const response = await this.put({url: `team/users/${id}`})

                if (response.status == 200) {
                    alert(t("general.done"))
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)
                this.getTeam()
            },
            async addOrRemove() {
                this.SET_LOADING(true)
                const response = await this.put({url: `team/users/${this.email}`})

                if (response.status) {
                    if (response.status == 200) {
                        alert(t("general.done"))
                        this.show = false
                    } else if (response.status == 204) {
                        alert(t('teams.you'))
                    } else if (response.status == 404) {
                        alert(t('teams.notfound'))
                    } else {
                        alert(response.data.message)
                    }
                } else {
                    alert(response.data.message)
                }
                console.log(response.data)
                this.selected = null
                this.getTeam()
            },
            async getTeam() {
                this.SET_LOADING(true)
                const res = await this.get({ url: "team/users" })
                if (res.status == 200) {
                    this.team = res.data.team
                    console.log(res.data.team)
                } else {
                    alert(res.data.message)
                }
                this.SET_LOADING(false)
                if (!this.loading) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }
            },
            t
        }  
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
</style>
