import { createRouter, createWebHistory } from 'vue-router'
import TreinosView from '@/views/TreinosView.vue'
import NewTreinoView from '@/views/NewTreinoView.vue'
import ExerciciosView from '@/views/ExerciciosView.vue'
import FormacoesViews from '@/views/FormacoesView.vue'
import LoginComponent from '@/components/auth/LoginComponent.vue'
import NewExercicioView from '@/views/NewExercicioView.vue'
import HomeView from '@/views/HomeView.vue'
import ProfileView from '@/views/ProfileView.vue'
import PaymentView from '@/views/PaymentView.vue'
import VideosView from '@/views/VideosView.vue'
import UsersViews from '@/views/admin/UserView.vue'
import PlansView from '@/views/admin/PlansView.vue'
import TeamsView from '@/views/admin/TeamsView.vue'
import TrashView from '@/views/admin/TrashView.vue'
import NewTeamView from '@/views/admin/NewTeamView.vue'
import TeamView from '@/views/team/TeamView.vue'
import UsersView from '@/views/team/UsersView.vue'
import CategoriesView from '@/views/team/CategoriesView.vue'
import TeamTreinosView from '@/views/team/TreinosView.vue'
import MyTeamsView from '@/views/TeamsView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import HelpComponent from '@/components/HelpComponent.vue'
import CampsView from '@/views/CampsView.vue'
import NotifyView from '@/views/admin/NotifyView.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView
    },
    {
        path: '/profile',
        name: 'Profile',
        component: ProfileView
    },
    {
        path: '/payment',
        name: 'Plans',
        component: PaymentView
    },
    {
        path: '/treinos',
        name: 'Treinos',
        component: TreinosView
    },
    {
        path: '/new-treino',
        name: 'Novo Treino',
        component: NewTreinoView
    },
    {
        path: '/formacoes',
        name: 'Formacoes Taticas',
        component: FormacoesViews
    },
    {
        path: '/videos',
        name: 'Videos',
        component: VideosView
    },
    {
        path: '/treino/:id',
        name: 'Exercicios',
        component: ExerciciosView
    },
    {
        path: '/marcar/:id',
        name: 'Marcar',
        component: NewExercicioView
    },
    {
        path: '/marcar',
        name: 'MarcarFormacao',
        component: NewExercicioView
    },
    {
        path: '/clubes',
        name: 'MeusClubes',
        component: MyTeamsView
    },
    {
        path: '/select',
        name: 'SelectCamp',
        component: CampsView
    },
    // teams view
    {
        path: '/team/profile',
        name: 'MyTeam',
        component: TeamView
    },
    {
        path: '/team/users',
        name: 'Users Team',
        component: UsersView
    },
    {
        path: '/team/categories',
        name: 'Categories Team',
        component: CategoriesView
    },
    {
        path: '/team/treinos',
        name: 'Treinos Team',
        component: TeamTreinosView
    },

    // Admin Routes
    {
        path: '/admin/users',
        name: 'Admin Users',
        component: UsersViews
    },
    {
        path: '/admin/plans',
        name: 'Admin Plans',
        component: PlansView
    },
    {
        path: '/admin/teams',
        name: 'Admin Teams',
        component: TeamsView
    },
    {
        path: '/admin/clean',
        name: 'Admin Trash',
        component: TrashView
    },
    {
        path: '/admin/notify',
        name: 'Admin Notify',
        component: NotifyView
    },
    {
        path: '/admin/new-team',
        name: 'New Teams',
        component: NewTeamView
    },

    // Auth Routes
    { path: '/login', name: 'login', component: LoginComponent },
    { path: '/signup', name: 'signup', component: LoginComponent },
    { path: '/password/email', name: 'forget', component: LoginComponent },
    { path: '/password/reset/:email/:key', name: 'changePass', component: LoginComponent },
    { path: '/help', name: 'help', component: HelpComponent },

    // 404
    { path: '/:catchAll(.*)', name: '404', component: NotFoundView}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
