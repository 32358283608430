<template>
    <footer class="main-footer">
        <strong>Copyright &copy; 2024 <a href="https://planejadordetreino.com/">Planejador de Treinos</a>.</strong>
        All rights reserved.
    </footer>
</template>

<script>
    export default {
        name: "FooterComponet",   
    }
</script>
