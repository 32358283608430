<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <select-esporte-component 
            v-if="onSelect" 
            @select="selectEsporte"
            :show="true"
            @hide="() => { this.onSelect = false}"
        ></select-esporte-component>

        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('exercices.formations') }}</h3>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <button class="btn btn-flat btn-success" @click="print">
                    <i class="fa fa-print"></i> {{ t('datatable.print') }}
                </button>
                <button class="btn btn-flat btn-warning" @click="() => { this.onSelect = true;}">
                    <i class="fa fa-plus"></i> {{ t('datatable.add') }}
                </button>
                <data-table v-if="exercicios" class="table table-striped table-bordered" :data="exercicios" :columns="columns"
                    :options="{responsive: true, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th></th>
                            <th>{{ t('general.actions') }}</th>
                            <th>{{ t('treinos.clube') }}</th>
                            <th>{{ t('treinos.description') }}</th>
                            <th>{{ t('exercices.at') }}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>

        <!-- Modals dos exercicios -->
        <modal-component :classList="['col-10', 'col-sm-7 col-md-5']" v-if="selected" :title="selected.clube"
            @hide="() => {this.selected = null}"
            :show="true"
        >
            <form ref="form" @submit.prevent="update" class="p-1 col-12 m-0">
                <div class="p-1 form-group m-0 p-2 col-12 position-relative">
                    <input type="file" name="img" class="d-none" ref="file" @change="changeFile">
                    <img :src="selected.img_url" class="col-11 p-1 m-auto">
                    <button v-if="!selected.category_id" class="btn btn-warning position-absolute bottom-0 end-0" type="button" @click="openFile">
                        <i class="fa fa-upload"></i>
                    </button>
                </div>

                <div class="p-1 form-group m-0 p-2 col-12" v-for="input in inputs" :key="input.model">
                    <span class="m-0 col-12 p-0 py-1 row justify-content-between" v-if="input.header">
                        <input :id="['label_' + input.model]" class="editable col-10" v-model="selected['h_' + input.model]" contenteditable>
                        <i class="fa fa-edit mx-2" style="cursor: pointer" @click="edit('label_'+input.model)"></i>
                    </span>
                    <span class="m-0" v-else>{{ input.label }}</span>
                    <input :type="input.type" :name="input.model" v-model="selected[input.model]" class="form-control" required>
                </div>
                
                <div class="p-1 form-group m-0 p-2 col-12">
                    {{ selected.category ? selected.category.name : selected.category }}
                    <span for="descricao">{{ t('exercices.description') }}</span>
                    <textarea class="form-control" rows="4" maxlength="800" required v-model="selected.descricao"></textarea>
                </div>

                <div class="p-1 form-group m-0 py-2">
                    <button type="submit" class="btn btn-primary">{{ t('general.update_data') }}</button>
                </div>
            </form>
        </modal-component>

        <modal-component :classList="['col-11', 'col-sm-9', 'p-1']" v-if="view"
            @hide="() => {this.view = null}"
            :show="true"
        >
            <img :src="view" class="col-12">
        </modal-component>
        <!-- ./Modals dos exercicios -->
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import SelectEsporteComponent from '@/components/SelectEsporteComponent.vue'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { t } from '@/lang'
    import { decodeEntities, getExImage, deleteExercice, fillData } from '@/utilities/HelpFunctions.js'
    import { check, exercicesToPrint } from '@/utilities/print.js'
    import api from '@/utilities/Api.js'

    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "FormacoesView",
        components: {
            DataTable,
            ModalComponent,
            SelectEsporteComponent,
        },
        data() {
            return {
                exercicios: null,
                showModal: false,
                printNow: false,
                onSelect: false,
                columns: [
                    {data: 'id'},
                    {data: null, render: data =>  `
                        <div class='icheck-primary'>
                            <input type="checkbox" name='check${data.id}' id="check${data.id}" data-id='${data.id}' data-action='check'>
                            <label for='check${data.id}'></label>
                        </div>`
                    },
                    {data: null, render: data => `
                        <div class="row p-0 m-0 flex-nowrap">
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-primary' data-id='${data.id}' data-action='view'>
                                    <i class='fa fa-eye' data-id='${data.id}' data-action='view'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-warning' data-id='${data.id}' data-action='edit'>
                                    <i class='fa fa-edit' data-id='${data.id}' data-action='edit'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-danger' data-id='${data.id}' data-action='delete'>
                                    <i class='fa fa-trash' data-id='${data.id}' data-action='delete'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-success' data-id='${data.id}' data-action='download'>
                                    <i class='fa fa-download' data-id='${data.id}' data-action='download'></i>
                                </button>    
                            </div>
                        </div>`
                    },
                    {data: 'clube'},
                    {data: null, render: data => `<span class='no-br'>${data.descricao.substr(0, 35)}<i class='d-none'>${data.descricao.substr(35, data.descricao.length)}</i></span>`},
                    {data: null, render: data => `${new Date(data.created_at).toLocaleString()}`}
                ],
                inputs: [
                    {label: t('treinos.clube'), type: "text", model: 'clube'},
                    {label: t('treinos.coach'), type: "text", model: 'professor', hmodel: 'h_professor', header: true},
                    {label: t('treinos.category'), type: "text", model: 'categoria', hmodel: 'h_categoria', header: true},
                    {label: t('treinos.date'), type: "date", model: 'data', hmodel: 'h_data', header: true},
                    {label: t('treinos.local'), type: "text", model: 'local', hmodel: 'h_local', header: true}
                ],
                selected: null,
                view: null,
                esporte: null,
            }
        },
        created() {
            document.title = "Formacoes tacticas"
            this.getExercices()
        },
        
        computed: {
            ...mapState(['loading'])
        },
        methods: {
            ...mapMutations(['SET_LOADING']),
            ...mapActions(['get', 'put', 'delete', 'post']),
            async getExercices() {
                this.SET_LOADING(true)

                const response = await this.get({
                    url: 'formactions?type=image'
                })
                
                if (response.status != 200) {
                    alert(response.data.message)
                    this.$router.push("/formacoes")
                } else {
                    this.exercicios = response.data.formacoes
                }
                this.SET_LOADING(false)
                if (!this.loading && this.$el.querySelector('#my-table')) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }

                check(null, [])
            },
            async handleClick(event) {
                if (event.target.hasAttribute("data-action")) {
                    let [exercice] = this.exercicios.filter(ex => ex.id == event.target.getAttribute("data-id"))
                    const excludes = ['category', 'category_id']

                    const defaults = {
                        h_categoria: t('treinos.category'),
                        h_data: t('treinos.date'),
                        h_professor: t('treinos.coach'),
                        h_local: t('treinos.local')
                    }

                    switch(event.target.getAttribute("data-action")) {
                        case 'view':
                            this.SET_LOADING(true)
                            this.view = await getExImage(exercice.id, this.get, 'formactionimg/')
                            this.SET_LOADING(false)
                            break
                        case 'edit':
                            // decode entities
                            exercice = Object.assign({}, exercice)
                            for(const key in exercice) {
                                if (!excludes.includes(key)) {
                                    if (key.startsWith('h_')) {
                                        if (!exercice[key] || exercice[key].length <= 0) {
                                            exercice[key] = defaults[key]
                                        }
                                    }
                                    exercice[key] = decodeEntities(exercice[key])
                                }
                            }
                            // get image
                            this.SET_LOADING(true)
                            this.selected = exercice

                            this.selected.img_url = await getExImage(exercice.id, this.get, 'formactionlogo/')
                            this.SET_LOADING(false)
                            break
                        case 'delete':
                            // confirm delete
                            if (confirm(t('general.do-you-want'))) {
                                this.SET_LOADING(true)
                                await deleteExercice(exercice.id, this.delete, 'formactions')
                                await this.getExercices()
                                this.SET_LOADING(false)
                            }
                            break
                        case 'check':
                            check(exercice)
                            break
                        case 'download':
                            this.download(exercice)
                            break
                    }
                }
            },
            async download(exercice) {
                this.SET_LOADING(true)
                const url = await getExImage(exercice.id, this.get, 'formactionimg/')
                const a = document.createElement("a")
                a.setAttribute("download", exercice.professor + "-" + exercice.clube + ".png")
                a.setAttribute("href", url)
                a.setAttribute("target", "_blanck")

                a.click()
                this.SET_LOADING()
            },
            selectEsporte(name) {
                this.$router.push(`/select?esporte=${name}`)
            },
            t,
            async update({target}) {
                if(target.checkValidity()) {
                    this.SET_LOADING(true)
                    const f = new FormData()

                    if (!this.selected.category_id) {
                        f.append("img", this.selected.img)
                    }
                    delete this.selected.img
                    if (!fillData(f, this.selected)) {
                        alert(t("exercices.fill-error"))
                        this.SET_LOADING(false)
                        return null
                    }
                    
                    const response = await this.post({
                        url: 'formactions/' + this.selected.id,
                        data: f,
                        formData: true
                    })
                    if (response.status == 200 || response.status == 204) {
                        alert(t('general.updated'))
                        this.getExercices()
                    } else {
                        alert(response.data.message)
                    }
                    this.selected = null
                    this.SET_LOADING(false)
                }
            },
            getPrintEx() {
                return exercicesToPrint()
            },
            openFile() {
                this.$refs.file.click()
            },
            changeFile(evt) {
                const [img] = evt.target.files
                if (img) {
                    this.selected.img_url = URL.createObjectURL(img)
                    this.selected.img = img
                }
            },
            print() {
                if (this.getPrintEx().length < 1) {
                    return null
                }
                const p = new URLSearchParams()
                let i = 0
                for(let ex of this.getPrintEx()) {
                   p.append(`exs[${i}]`, ex.id) 
                   i++
                }
                const lang = localStorage.getItem("lang_code") ? localStorage.getItem("lang_code").substring(0, 2).substring(0, 2):"pt"
                p.append("auth_token", sessionStorage.getItem("auth_token"))
                p.append("lang", lang)
                open(api.printUrl + "/fr?" + p.toString(), "_blanck")
            },
            edit(id) {
                this.$el.querySelector(`#${id}`).focus()
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
    .upload-btn {
        bottom: 0px;
        right: 0px;
    }
</style>
