<template>
    <div class="card card-primary card-outline col-12 p-0 shadow-lg m-auto">
        <div class="card-header">
            <img :src="logo" class="col-12">
        </div>
        <div class="card-body">
            <p class="login-box-smg text-center" v-if="pages[authPage - 1].text">
                {{ pages[authPage - 1].text }}
            </p>
            <p class="login-box-smg text-center" v-else>
                Ola, {{ pages[authPage - 1].text }}! Insira sua nova Senha para voltar a lanejar
            </p>

            <form @submit.prevent="submit">
                <div class="input-group mb-3"
                    v-for="input in pages[authPage - 1].inputs" :key="input"
                >
                    <input :type="inputs[input - 1].type" class="form-control" :placeholder="inputs[input - 1].name" 
                    v-model="user[inputs[input - 1].model]">
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas" :class="inputs[input - 1].icon"></span>
                        </div>
                    </div>
                </div>

                <div class="input-group mb-3" v-if="pages[authPage - 1].inputs == 0">
                    <input type="password" class="form-control" :placeholder="t('auth.changePass.newpass')" v-model="user.password">
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-end">
                    <div class="col-12 col-md-4">
                        <button class="btn btn-primary btn-block" @submit.prevent="submit">
                            {{ pages[authPage - 1].action }}
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div class="card-footer">
            <p class="mb-1" v-for="link in pages[authPage - 1].footer" :key="link.to">
                <a @click.prevent="goTo(link.to)" href="#" class="text-primary">{{ link.text }}</a>
            </p>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState, mapActions } from 'vuex'
    import { t } from '@/lang/index.js'

    export default {
        name: "LoginComponent",
        components: {
        },
        data() {
            return {
                logo: '/images/logo.jpg',
                user: {
                    email: '',
                    password: '',
                    name: '',
                    phone: ''
                },
                inputs: [
                    {name:t('auth.login.email'), icon: 'fa-envelope', type: 'email', model: 'email'},
                    {name: t('auth.login.password'), icon: 'fa-lock', type: 'password', model: 'password'},
                    {name: t('auth.signup.name'), icon: 'fa-user', type: 'text', model: 'name'},
                    {name: t('auth.signup.phone'), icon: 'fa-phone', type: 'number', model: 'phone'}
                ],
                pages: [
                    {
                        inputs: 2,
                        text: t('auth.login.text'),
                        title: t('auth.login.title'),
                        action: t('auth.login.submit'),
                        footer: [
                            {text: t('auth.login.link1'), to: 3},
                            {text: t('auth.login.link2'), to: 2}
                        ]
                    },
                    {
                        inputs: 4,
                        text: t('auth.signup.text'),
                        title: t('auth.signup.title'),
                        action: t('auth.signup.submit'),
                        footer: [
                            {text: t('auth.login.link1'), to: 3},
                            {text: t('auth.signup.link2'), to: 1}
                        ]
                    },
                    {
                        inputs: 1,
                        text: t('auth.forget.text'),
                        title: t('auth.forget.title'),
                        action: t('auth.forget.submit'),
                        footer: [
                            {text: t('auth.forget.link'), to: 1}
                        ]
                    },
                    {
                        inputs: 0,
                        action: t('auth.changePass.submit'),
                        footer: [
                            {text: t('auth.forget.link'), to: 1}
                        ]
                    }
                ]
            }
        },
        computed: {
            ...mapState(['authPage'])
        },
        async created() {
            document.body.classList.add("login-page")
            const routes = ['login', 'signup', 'forget', 'changePass']
            let pageName = this.$route.name
            if (!routes.includes(this.$route.name)) {
                pageName = routes[0]
                this.goTo(1)
            }
            if (pageName == 'changePass') {
                // enviar requeste e verificar
                this.user.email = this.$route.params.email
                this.user.token = this.$route.params.key

                await this.getUser()
            }
            this.SET_AUTH(routes.indexOf(pageName) + 1)
            document.title = t(`auth.${pageName}.title`)
        },
        methods: {
            ...mapMutations(['SET_USER', 'SET_LOADING', 'SET_AUTH']),
            ...mapActions(['post']),
            goTo(to) {
                // change auth page
                const routes = ['login', 'signup', 'forget', 'changePass']
                this.$router.push({name: routes[to - 1]})
                this.SET_AUTH(to)
            },
            async submit() {
                this.SET_LOADING(true)
                const response = await this.post({url: `auth/${this.$route.name}`, data: this.user})
                console.log(response)
                if (response.status == 200) {
                    if (this.$route.name == 'login' || this.$route.name == 'signup' || this.$route.name == 'changePass') {
                        sessionStorage.setItem('auth_token', response.data.auth_token)
                        sessionStorage.removeItem('role')
                        sessionStorage.setItem('role', response.data.user.level)

                        sessionStorage.removeItem('team')
                        if (response.data.user.team) {
                            sessionStorage.setItem('team', true)
                        } else

                        alert("Bem vindo")
                        location = '/'
                    } else if (this.$route.name == 'forget') {
                        alert(response.data.message)
                        this.pages[2].text = t('auth.forget.done')
                    }
                } else {
                    alert(response.data.message)
                    if (response.status == 422 && response.data.errors) {
                        for(let prop in response.data.errors) {
                            const msg = response.data.errors[prop].join('\n')
                            alert(msg)
                        }
                    }
                }
                this.SET_LOADING(false)
            }, t,
            async getUser() {
                this.SET_LOADING(true)
                // enviar request para verificar email e token
                const response = await this.post({
                    url: 'auth/reset',
                    data: this.user
                })

                alert(response.data.message)
                if (response.status != 200) {
                    location = '/password/email'
                }

                this.SET_LOADING(false)
            }
        }
    }
</script>
