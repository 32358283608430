<template>
    <div class="row p-0 m-0">
        <div class="col-12 col-sm-3 p-1">
            <span class="d-block">{{ t('general.select_color') }}</span>
            <input type="color" v-model="shape.color" class="form-control" @change="send">
        </div>
        <div class="col-12 col-sm-3 p-1">
            <span class="d-block">{{ t('arrows.width') }}</span>
            <input type="number" min="3" max="20" v-model="shape.width" class="form-control" @change="send">
        </div>
        <div class="col-12 col-sm-3 p-1">
            <span class="d-block">{{ t('shapes.shape') }}</span>
            <select class="form-control" v-model="shape.shape" @change="send">
                <option value="circ">{{ t('shapes.circ') }}</option>
                <option value="rect">{{ t('shapes.rect') }}</option>
                <option value="triangle">{{ t('shapes.triangle') }}</option>
            </select>
        </div>
    </div>
</template>

<script>
    import { t } from '@/lang'
    export default {
        name: 'TextComponent',
        emits: ['submit'],
        props: {

        },
        data() {
            return {
                shape: {
                    color: '#000000',
                    width: 5,
                    shape: 'rect'
                }
            }
        },
        methods: {
            t,
            send() {
                this.$emit('submit', this.shape)
            }
        }
    }
</script>