<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <modal-component :classList="['col-10', 'col-sm-8', 'col-md-7', 'col-lg-5', 'col-xlg-5']" v-if="show" :title="t('teams.access')"
            @hide="() => {this.$router.push('/admin/teams')}"
            :show="true"
        >
            <p>
                <b>{{ t('auth.login.email')}}</b>: {{ team.email }}
            </p>
            <p>
                <b>{{ t('auth.login.password')}}</b>: {{ pass }}
            </p>
        </modal-component>
        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('teams.new') }}</h3>
            </div>
            <div class="card-body">
                <form @submit.prevent="submit">
                    <div class="row p-2 m-0">
                        <div class="col-12 row p-4 m-0 justify-content-center position-relative">
                            <div class="form-group col-10 col-md-5 col-lg-3">
                                <input type="file" ref="file" class="d-none" @change="changeFile" />
                                <img class="image-responsive responsive col-12" :src="img">
                                <button class="field field-image btn btn-warning position-absolute text-white bottom-0 right-0" :error="errors.image" type="button"
                                    @click="() => { this.$refs.file.click() }"
                                >
                                    <i class="fa fa-upload"></i>
                                </button>
                            </div>
                        </div>
                        <div class="form-group col-12 col-md-6 col-lg-4">
                            <label>Nome do clube</label>
                            <input required @click="resetErrors" type="text" :error="errors.team" v-model="team.team" class="form-control field field-team" />
                        </div>
                        <div class="form-group col-12 col-md-6 col-lg-4">
                            <label>Email (ja existente)</label>
                            <input required @click="resetErrors" type="email" :error="errors.email" v-model="team.email" class="form-control field field-email" />
                        </div>
                        <div class="form-group col-12 col-md-6 col-lg-4">
                            <label>Estado ou cidade</label>
                            <input required @click="resetErrors" type="text" :error="errors.location" v-model="team.location" class="form-control field field-location" />
                        </div>
                        <div class="form-group col-12 col-md-6 col-lg-4">
                            <label>Fim da assinatura</label>
                            <input required @click="resetErrors" type="datetime-local" :error="errors.end_subscription" v-model="team.end_subscription" class="form-control field field-end_subscription" />
                        </div>

                        <div class="form-group col-12 col-md-6 col-lg-4">
                            <label>Número de usuários</label>
                            <input required @click="resetErrors" type="number" :error="errors.max_users" step="1" min="1" v-model="team.max_users" class="form-control field field-max_users" />
                        </div>
                        <div class="form-group col-12 col-md-6 col-lg-4">
                            <label>Número de categorias</label>
                            <input required @click="resetErrors" type="number" :error="errors.max_categories" step="1" min="1" v-model="team.max_categories" class="form-control field field-max_categories" />
                        </div>
                        <div class="form-group col-12 col-md-6 col-lg-4">
                            <label style="color: transparent !important">-</label>
                            <button type="submit" class="form-control btn btn-primary">
                                Criar clube
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { t } from '@/lang'
    import { mapMutations, mapActions } from 'vuex'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'

    export default {
        name: "NewTeamView",
        components: {
            ModalComponent
        },
        data() {
            return {
                show: false,
                team: {
                    team: null,
                    name: null,
                    email: null,
                    phone: null,
                    location: null,
                    end_subscription: null,
                    max_users: null,
                    max_categories: null,
                    image: null,
                },
                errors: {
                    team: false,
                    name: false,
                    email: false,
                    phone: false,
                    location: false,
                    end_subscription: false,
                    max_users: false,
                    max_categories: false,
                    image: false,
                },
                img: null,
                pass: ''
            }
        },
        methods: {
            t,
            ...mapMutations(['SET_LOADING']),
            ...mapActions(['post']),
            async submit() {
                this.SET_LOADING(true)
                this.team.end_subscription = (new Date(this.team.end_subscription)).toLocaleString('af')
                
                const formData = new FormData()
                for(let prop in this.team) {
                    formData.append(prop, this.team[prop])
                }

                const res = await this.post({url: 'admin/teams', data: this.team, formData: formData})
                if (res.status == 422) {
                    alert(this.t('general.422'))
                    for(let field in res.data.errors) {
                        this.errors[field] = true
                        alert(res.data.errors[field][0])
                    }
                } else if (res.status >= 200 && res.status < 300) {
                    // this.pass = res.data.team.user.pass
                    // this.show = true
                    alert(t("general.done"))
                    this.$router.push("/admin/teams")
                } else {
                    alert("Ocorreu um erro: " + res.data.message)
                }
                console.log(res)
                this.SET_LOADING(false)
            },
            resetErrors() {
                for(let err in this.errors) {
                    this.errors[err] = false
                }
            },
            changeFile(evt) {
                [this.team.image] = evt.target.files
                if (this.team.image) {
                    this.img = URL.createObjectURL(evt.target.files[0])
                } else {
                    this.img = null
                }
            }
        }
    }
</script>

<style scoped>
    .form-control, .btn {
        transition: all .4s ease-in-out;
    }
    [error="true"] {
        border: 1px solid red;
    }
</style>
