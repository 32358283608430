<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <router-link :to="home_url" class="brand-link">
            <img src="/logo.png" alt="Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
            <span class="brand-text font-weight-light">{{ text }}</span>
        </router-link>

        <!-- Sidebar -->
        <div class="sidebar">
            <!-- SidebarSearch Form -->
            <div class="form-inline">
                <div class="input-group" data-widget="sidebar-search">
                    <input class="form-control form-control-sidebar" v-model="search" type="search" placeholder="Search" aria-label="Search">
                    <div class="input-group-append">
                        <button class="btn btn-sidebar">
                            <i class="fas fa-search fa-fw"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li  class="nav-item" :class="{'menu-open': item.selected}" v-for="item in filteredMenu" :key="item.url">
                        <template v-if="(!item.admin && !item.team) || (item.admin && ['admin', 'master'].includes(this.role)) || (item.team && this.haveTeam)">
                            <router-link :to="item.url" class="nav-link d-flex flex-direction-row justify-content-between px-1" :class="{'active': item.selected}" v-if="item.url != '#' && !item.blank">
                                <span class="">
                                    <i :class="item.icon" class="p-1 me-2 nav-icon"></i>
                                    <p>
                                        {{ item.name }}
                                    </p>
                                </span>
                                <i class="right fas fa-angle-left m-0 p-0" v-if="item.menu != undefined"></i>
                            </router-link>
                            
                            <a :href="item.url" target="_blank" class="hover rounded d-flex flex-direction-row px-1 py-2" v-else-if="item.blank">
                                <i :class="item.icon" class="p-0 me-2 nav-icon col-auto my-0"></i>
                                <p class="p-0 m-0">
                                    {{ item.name }}
                                </p>
                            </a>

                            <div class="nav-link d-flex flex-direction-row justify-content-between px-1" :class="{'active': item.selected}" v-else>
                                <span class="">
                                    <i :class="item.icon" class="p-1 me-2 nav-icon"></i>
                                    <p>
                                        {{ item.name }}
                                    </p>
                                </span>
                                <i class="right fas fa-angle-left m-0 p-0" v-if="item.menu != undefined"></i>
                            </div>
                            <ul class="nav nav-treeview">
                                <li class="nav-item" v-for="subitem in item.menu" :key="subitem.url">
                                    <router-link :to="subitem.url" class="nav-link d-flex flex-direction-row justify-content-start" :class="{'active': subitem.selected}">
                                        <i class="far fa-circle nav-icon p-1"></i>
                                        <p>{{ subitem.name }}</p>
                                    </router-link>
                                </li>
                            </ul>
                        </template>
                    </li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
    </aside>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "MainSideBar",
        props: {
            home_url: String,
            text: String,
            logo: String,
            menu: Array
        },
        data() {
            return {
                icon_class: "nav-icon fas ",
                role: 'user',
                haveTeam: false,
                search: ''
            }
        },
        created() {
            this.verifyAdmin()
        },
        updated() {
            this.verifyAdmin()
        },
        computed: {
            filteredMenu() {
                return this.menu
            }
        },
        methods: {
            ...mapGetters(['getUser']),
            verifyAdmin() {
                this.role = sessionStorage.getItem('role')
                this.haveTeam = sessionStorage.getItem('team')
            }
        }
    }
</script>

<style lang="scss" scoped>
    div.nav-link {
        cursor: pointer;
        color: #d0d0d0;
    }
    .hover:hover {
        background-color: rgba(241, 235, 235, 0.2);
    }
</style>
