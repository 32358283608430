<!-- eslint-disable no-prototype-builtins -->
<template>
    <div v-if="sport" class="row p-1 justify-content-around col-12 p-1 overflow-x-scroll position-relative bg-light m-0" ref="fs">
        <modal-component :show="showModal" :title="t('exercices.modal')" :classList="['col-10 col-md-8']"
            @hide="closeModal"
        >
            <form ref="form" @submit.prevent="() => { this.showModal = false }" class="row p-1">
                <div class="col-12 row justify-content-center" v-if="isFormation && team < 0">
                    <div class="col-12 col-sm-6 col-md-5 position-relative" style="min-height: 4rem">
                        <input type="file" ref="file" class="d-none" @change="changeFile">
                        <img class="image-responsive responsive col-12" :src="img">
                        <button class="btn btn-warning position-absolute text-white bottom-0 right-0" type="button"
                            @click="() => { this.$refs.file.click() }"
                        >
                            <i class="fa fa-upload"></i>
                    </button>
                    </div>
                </div>
                <div class="p-1 form-group m-0 py-2" v-for="input in inputs" :key="input.model" :class="input.classe">
                    <span class="m-0 col-12 p-0 row justify-content-between" v-if="input.model != 'titulo' && (!isFormation || input.hmodel)">
                        <input :id="['label_'+input.model]" class="editable col-10" v-model="exercice[input.hmodel]" contenteditable>
                        <i class="fa fa-edit mx-2" style="cursor: pointer" @click="edit('label_'+input.model)"></i>
                    </span>
                    <span class="m-0 col-12 p-0 row justify-content-between" v-else-if=" !(isFormation && !video && team >= 0 && ['clube', 'professor', 'categoria'].includes(input.model))">
                        <input :id="['label_'+input.model]" v-model="input.text" class="editable col-12" disabled>
                    </span>

                    <input
                        :placeholder="input.ex" 
                        v-model="exercice[input.model]" 
                        :name="input.model"
                        v-if="!(isFormation && team >= 0 && ['categoria'].includes(input.model))"
                        :disabled="(isFormation && team >= 0 && ['professor', 'local', 'clube'].includes(input.model))"
                        :id="input.model" 
                        class="form-control" 
                        :type="input.type ? input.type:'text'"
                        required
                    >
                </div>

                <div class="p-1 form-group m-0 py-2 col-12">
                    <span for="descricao">{{ t('exercices.description') }}</span>
                    <textarea class="form-control" rows="4" maxlength="800" required v-model="exercice.descricao"></textarea>
                </div>

                <div class="p-1 form-group m-0 py-2">
                    <button type="submit" class="btn btn-primary">{{ t('general.done') }}</button>
                </div>
            </form>
        </modal-component>
        <div class="d-flex p-1 m-0 col-auto overflow-x-scroll" v-show="treino || isFormation">
            <section class="col-auto p-1 bg-secondary position-relative" v-if="!previw">
                <i class="fas fa-expand-arrows-alt position-absolute toggle-fs text-light" @click="fullScreen" title="FullScreen mode"></i>
                <h5>{{ t('general.options') }}</h5>
                <div class="p-1 col-12" v-for="option in options" :key="option.ref" @click="() => { this.selectedOption = option.ref }">
                    <div class="btn col-12 btn-primary text-left" :class="{'btn-success': option.ref == selectedOption}">
                        <i class="fa fa-eye"></i> {{ option.text }}
                    </div>
                </div>
            </section>
            <section class="col-auto p-1 border border-white previw-tools bg-secondary" v-if="previw">
                <div class="col-12">
                    <img :src="img" class="p-0 m-0" style="max-width: 100px;">
                </div>
                <div class="col-12" v-for="label of inputs" :key="label.key">
                    <span style="text-transform: uppercase;" class="text-bold d-block">{{ label.text }}:</span>
                    <span class="d-block">{{ exercice[label.model] }}</span>
                </div>
                <div class="col-12">
                    <span style="text-transform: uppercase;" class="text-bold d-block">{{ t("treinos.description") }}:</span>
                    <div class="d-block">
                        <pre>{{ exercice['descricao'] }}</pre>
                    </div>
                </div>
            </section>
            <div class="position-relative" style="width: 630px; height: 472.5px;">
                <div class="not-allowed position-absolute" v-if="previw" style="width: 630px; height: 472.5px; z-index: 2">
                    Previw in progress...
                </div>
                <div class="canvas_cont p-0 m-0" id="canvas_cont"
                    @drop.prevent="drop"
                    style="width: 630px; height: 472.5px; z-index: 1"
                >
                <canvas id="canvas" ref="canvas" class="p-0 m-0 canvas" 
                    width="1280" height="960"
                    style="width: 630px !important; height: 472.5px !important;"
                ></canvas>
            </div>
            </div>
            <section class="col-auto" v-if="previw && !playing">
                <button class="btn btn-link text-dark" @click="() => {this.previw = null}">
                    <i class="fa fa-times"></i>
                </button>
            </section>
            <section class="col-auto" v-if="!previw">
                <h5>{{ t('general.actions') }}</h5>
                <div class="col-12 p-1">
                    <button class="btn btn-outline-info col-12" @click="alert">
                        <i class="fa fa-keyboard"></i> {{ t("short-cuts.short-cuts") }}
                    </button>
                </div>
                <div class="col-12 p-1">
                    <button class="btn btn-outline-primary col-12" @click="showInfo">
                        <i class="fa fa-text-height"></i> {{ t('general.info') }}
                    </button>
                </div>
                <div class="col-12 p-1">
                    <button class="btn btn-outline-primary col-12" @click="save">
                        <span v-if="!video">
                            <i class="fa fa-save"></i> {{ t('general.save') }}
                        </span>
                        <span v-else>
                            <i class="fa fa-play"></i> {{ t('general.previw') }}
                        </span>
                    </button>
                </div>
                <div class="col-12 p-1">
                    <router-link class="btn btn-primary" v-if="!isFormation && treino" :to="link">
                        <i class="fa fa-arrow-left"></i>
                    </router-link>
                </div>
                <div class="col-12 p-1">
                    <router-link class="btn btn-primary" v-if="isFormation && !video" to="/formacoes">
                        <i class="fa fa-arrow-left"></i>
                    </router-link>
                </div>
                <div class="col-12 p-1">
                    <router-link class="btn btn-primary" v-if="isFormation && video" to="/videos">
                        <i class="fa fa-arrow-left"></i>
                    </router-link>
                </div>
                <div class="col-12 p-1">
                    <button class="btn btn-danger mx-1" @click="deleteHandler">
                        <i class="fa fa-trash"></i>
                    </button>

                    <button class="btn btn-warning mx-1" @click="hideObject" v-if="isFormation && video">
                        <i class="fa fa-eye-slash"></i>
                    </button>
                </div>

                <div class="col-12" v-if="video">
                    <div class="col-12 p-1">
                        <button class="btn btn-outline-dark">
                            {{ frame + " - " + frames }}
                        </button>
                    </div>
                    <div class="col-12 p-1">
                        <button class="btn btn-outline-primary" @click="previous">
                            <i class="fa fa-arrow-left"></i>
                        </button>
                    </div>
                    <div class="col-12 p-1">
                        <button class="btn btn-outline-primary" @click="del">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div class="col-12 p-1">
                        <button class="btn btn-outline-primary" @click="next">
                            <i class="fa fa-arrow-right"></i>
                        </button>
                    </div>
                    <div class="col-12 p-1">
                        <button class="btn btn-outline-primary" @click="add">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-12 p-1" style="background-color: #e0e0e0;" v-if="(treino || isFormation) && !previw">
            <players-component :sport="sport" 
                v-if="['players', 'goleiros'].includes(selectedOption)"
                :goleiros="selectedOption == 'goleiros'"
                @click="drop({layerX: 630 / 2, layerY: 472.5 / 2})"
            ></players-component>
            <lines-component v-if="selectedOption == 'arrows'" @submit="data => { this.objects.line = data }"></lines-component>
            <text-component v-if="selectedOption == 'text'" @add="drawText"></text-component>
            <shapes-component v-if="selectedOption == 'shapes'" @submit="data => { this.objects.shape = data }"></shapes-component>
            <icons-component :sport="sport" v-if="selectedOption == 'items'" @click="drop({layerX: 630 / 2, layerY: 472.5 / 2})"></icons-component>
            <personal-component v-if="selectedOption == 'personal'" @click="drop({layerX: 630 / 2, layerY: 472.5 / 2})"/>
            <shirts-component v-if="selectedOption == 'shirts'"/>
            <div class="col-12 p-1" v-if="['shapes', 'arrows'].includes(selectedOption)">
                <div class="icheck-primary d-inline">
                    <input type="checkbox" id="checkboxdraw" @change="disableObjects" v-model="freeDrawing">
                    <label for="checkboxdraw">
                    </label>
                </div>
                <span class="d-inline">{{ t('general.draw') }}</span>
            </div>
        </div>
        <div class="p-2 col-12 d-flex row justify-content-center" style="background-color: #e0e0e0;" v-if="previw">
                <div class="col-auto">
                    <button class="btn btn-primary mx-1" @click="saveVideo" v-if="!playing">
                        <i class="fa fa-save"></i> {{ t('general.save-video') }}
                    </button>
                    <button class="btn btn-primary mx-1" @click="play" v-if="!playing">
                        <i class="fa fa-sync"></i>
                    </button>
                    <label class="label px-1" v-if="!playing">{{ t("general.speed") }}: </label>
                    <div class="col-3 col-sm-2 d-inline-block" v-if="!playing">
                        <input class="form-control" type="number" step="0.1" min="0.8" max="5" v-model="speed">
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapMutations } from 'vuex'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import { shortCutsList } from '@/utilities/KeyBoardShortCuts.js'
    import { t } from '@/lang'
    import { fabric } from 'fabric'
    import { getAngle, initCanvas, deleteObject, copyObject, setBackImage, hideObject, scale } from '@/utilities/DrawFunctions.js'
    import { drop } from '@/utilities/DropFunctions.js'
    import { save } from '@/utilities/Events'
    import { deleteHandler, showInfo } from '@/utilities/HelpFunctions.js'
    import PlayersComponent from '@/components/marcar/PlayersComponent.vue'
    import LinesComponent from '@/components/marcar/LinesComponent.vue'
    import TextComponent from '@/components/marcar/TextComponent.vue'
    import ShapesComponent from '@/components/marcar/ShapesComponent.vue'
    import IconsComponent from '@/components/marcar/IconsComponent.vue'
    import PersonalComponent from '@/components/marcar/PersonalComponent.vue'
    import ShirtsComponent from '@/components/marcar/ShirtsComponent.vue'
    import { saveFrame, loadFrame, previous, next, add, del, _saveVideo } from '@/utilities/VideoFunctions.js'

    export default {
        name: "NewExercicioView",
        data() {
            return {
                shortCutsList: shortCutsList,
                treino: null,
                showModal: false,
                isFormation: false,
                fakeTreino: {},
                user: null,
                team: -1,
                sport: null,
                category_id: null,
                options: [
                    {text: t('general.players'), ref: 'players'},
                    {text: t('general.goleiros'), ref: 'goleiros'},
                    {text: t('general.items'), ref: 'items'},
                    {text: t('general.arrows'), ref: 'arrows'},
                    {text: t('general.text'), ref: 'text'},
                    {text: t('general.shapes'), ref: 'shapes'},
                    {text: t('general.personal'), ref: 'personal'},
                    {text: t('general.t-shirts'), ref: 'shirts'},
                ],
                selectedOption: 'players',
                freeDrawing: false,
                exercice: {
                    titulo: '',
                    duracao: '',
                    blocos_de_duracao: '',
                    dimensao: '',
                    relacao_numerica: '',
                    descricao: '',
                    h_dimensao: t('treinos.exercices.dimensions'),
                    h_duracao: t('exercices.total_duration'),
                    h_relacao: t('treinos.exercices.relation'),
                    h_blocos: t('treinos.exercices.blocks'),
                },
                inputs: [
                    {name: 'title', model: 'titulo', text: t('exercices.title'), classe: 'col-12', ex: ''},
                    {name: 'duration', model: 'duracao', text: t('exercices.total_duration'), classe: 'col-12 col-md-6', ex: '', hmodel: 'h_duracao'},
                    {name: 'blocks', model: 'blocos_de_duracao', text: t('treinos.exercices.blocks'), classe: 'col-12 col-md-6', ex: ``, hmodel: 'h_blocos'},
                    {name: 'demension', model: 'dimensao', text: t('treinos.exercices.dimensions'), classe: 'col-12 col-md-6', ex: ``, hmodel: 'h_dimensao'},
                    {name: 'realation', model: 'relacao_numerica', text: t('treinos.exercices.relation'), classe: 'col-12 col-md-6', ex: ``, hmodel: 'h_relacao'},
                ],
                objects: {
                    line: {
                        dashed: false,
                        width: 3,
                        color: '#ff0000',
                        arrow: false,
                        freemode: false,
                        arc: false
                    },
                    shape: {
                        color: '#000000',
                        width: 5,
                        shape: 'rect'
                    }
                },
                lastObject: null,
                img: null,
                frames: 1,
                video: false,
                frame: 1,
                previw: false,
                playing: false,
                speed: 1,
                canvas: null
            }
        },
        components: {
            ModalComponent,
            PlayersComponent,
            LinesComponent,
            TextComponent,
            ShapesComponent,
            IconsComponent,
            PersonalComponent,
            ShirtsComponent
        },
        async created() {
            
        },
        async mounted() {
            document.title = t("exercices.new")
            if (this.$route.name == 'MarcarFormacao') {
                this.isFormation = true
                this.fakeTreino.esporte = {name: this.$route.query.esporte}
                this.treino = this.fakeTreino
                this.exercice = {descricao: ''}
                this.inputs = [
                    {name: 'clube', model: 'clube', text: t('treinos.clube'), classe: 'col-12', ex: ''},
                    {name: 'category', model: 'categoria', text: t('treinos.category'), classe: 'col-12 col-md-6', ex: ''},
                    {name: 'professor', model: 'professor', text: t('treinos.prof'), classe: 'col-12 col-md-6', ex: ``},
                    {name: 'local', model: 'local', text: t('treinos.local'), classe: 'col-12 col-md-6', ex: ``},
                    {name: 'data', model: 'data', text: t('treinos.date'), classe: 'col-12 col-md-6', ex: ``, type: 'date'},
                ]

                for(const input of this.inputs) {
                    this.exercice[input.model] = ''
                    if (input.model != 'clube' && !this.$route.query.video) {
                        input['hmodel'] = `h_${input.model}`
                        this.exercice[`h_${input.model}`] = input.text
                    }
                }
                this.SET_LOADING(true)
                const res = await this.get({url: 'sports/' + this.$route.query.esporte})
                if (res.status != 200) {
                    alert("Esporte nao encontrado")
                    location = "/"
                }
                this.sport = res.data.sport

                this.SET_LOADING(false)
            } else {
                this.SET_LOADING(true)

                const response = await this.get({
                    url: 'treinos/' + this.$route.params.id
                })
                this.link = '/treino/' + this.$route.params.id

                if (response.status >= 200 && response.status < 300) {
                    this.treino = response.data.treino
                    this.sport = this.treino.esporte
                } else {
                    alert("Ocorreu um erro " + response.data.message)
                    if (response.status == 404) {
                        location = '/treinos'
                    }
                }
                this.SET_LOADING(false)
            }
            this.canvas = window.canvas
            this.SET_LOADING(true)
                
            await this.getUser()

            initCanvas(this)
            await this.setBackImage(this.$route.query.camp, this.sport.name)
            if (this.isFormation) {
                if (this.$route.query.video) {
                    if (!window.MediaRecorder) {
                        alert(t("general.support-recorder"))
                        this.$router.push("/videos")
                    }
                    this.video = true
                    // salvar o frame inicial
                    window.canvas.on("object:added", (e) => {
                        const obj = e.target 
                        // se for um objecto de animacao
                        if (!obj._curve_ && !obj.curve) {
                            if (!obj._animations) {
                                obj._animations = {
                                    start: this.frame
                                }
                            }
                            for(let i = this.frame; i <= this.frames; i++) { 
                                obj._animations[`f-${i}`] = {
                                    'scaleX': obj.scaleX,
                                    'scaleY': obj.scaleY,
                                    'angle': obj.angle,
                                    'left': obj.left,
                                    'top': obj.top,
                                    'visible': obj.visible
                                }
                            }
                        }
                    })
                    this.saveFrame()

                }
            }
            this.SET_LOADING(false)
        },
        methods: {
            async getUser() {
                this.SET_LOADING(true)
                
                const response = await this.get({url: 'user'})
                if (response.status == 200) {
                    this.user = response.data.user
                    if (!this.user.subscription_active) {
                        location = "/profile"
                    }
                } else if (response.status == 401) {
                    this.SET_USER(null)
                    this.$router.push('/login')
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)
            },
            alert() {
                alert(shortCutsList)
            },
            fullScreen() {
                try {
                    if (document.body.requestFullscreen) {
                        if (document.fullscreenElement || document.webkitFullscreenElement) {
                            document.exitFullscreen();
                        } else {
                            this.$refs.fs.requestFullscreen()
                        }
                    } else if (document.body.webkitRequestFullscreen) {
                        if (document.fullscreenElement || document.webkitFullscreenElement) {
                            document.webkitExitFullscreen();
                        } else {
                            this.$refs.fs.webkitRequestFullscreen()
                        }
                    }
                } catch (e) {
                    console.log(e)
                }
            },
            ...mapActions(['get', 'post']),
            ...mapMutations(['SET_LOADING']),
            t, getAngle, deleteObject, copyObject, setBackImage, hideObject,
            drop,
            edit(id) {
                this.$el.querySelector(`#${id}`).focus()
            },
            disableObjects() {
                window.canvas.forEachObject(object => object.selectable = false)
                window.canvas._activeObject = null
            },

            // Desenhos
            drawArrow(event, arrow = false) {
                const pointer = window.canvas.getPointer(event.e)
                const points = [pointer.x, pointer.y, pointer.x + 3, pointer.y + 3];
                let line;
                const options = {
                    strokeWidth: this.objects.line.width * scale,
                    strokeDashArray: this.objects.line.dashed ? [this.objects.line.width * 1.7 * scale, this.objects.line.width * 1.7 * scale] : [0,0],
                    fill: this.objects.line.color,
                    stroke: this.objects.line.color,
                    originX: 'center',
                    originY: 'center',
                    uuid : new Date().valueOf()
                }
                if (!this.objects.line.arc) {
                    line = new fabric.Line(points, options);
                } else {
                    line = new fabric.Path(`M ${points[0]} ${points[1]} A ${(points[2] - points[0]) / 2} ${(points[3] - points[1]) / 2} 0 0 1 ${points[2]} ${points[3]}`, options)
                }
                window.canvas.add(line);
                if (arrow) {
                    // draw arrow
                    const [centerX, centerY] = [(line.x1 + line.x2) / 2, (line.y1 + line.y2) / 2]
                    const deltaX = line.left - centerX;
                    const deltaY = line.top - centerY;
                    const triangle = new fabric.Triangle({
                        left: line.get('x1') + deltaX,
                        top: line.get('y1') + deltaY,
                        originX: 'center',
                        originY: 'center',
                        selectable: false,
                        pointType: 'arrow_start',
                        angle: -45,
                        width: (6 + this.objects.line.width * 1.8) * scale,
                        height: (6 + this.objects.line.width * 1.8) * scale,
                        fill: this.objects.line.color,
                        uuid : line.uuid
                    });
                    line.arrow = triangle
                    window.canvas.add(line.arrow)
                }
                this.lastObject = line
                window.canvas.renderAll();
            },
            updateArrow(event) {
               const pointer = window.canvas.getPointer(event.e)
               if (this.lastObject) {
                    if (this.lastObject.type == 'path') {
                        this.lastObject.set("path", 
                        `M ${this.lastObject.x1} ${this.lastObject.y1} A ${(pointer.x - this.lastObject.x1) / 2} ${(pointer.y - this.lastObject.y1) / 2} 0 0 1 ${pointer.x} ${pointer.y}`
                        )
                    } else {
                        this.lastObject.set({
                            x2: pointer.x,
                            y2: pointer.y
                        });
                    }
                    if (this.lastObject.arrow) {
                        this.lastObject.arrow.set({
                            'left': pointer.x + (this.lastObject.left - ((this.lastObject.x1 + this.lastObject.x2) / 2)),
                            'top': pointer.y + (this.lastObject.top - ((this.lastObject.y1 + this.lastObject.y2) / 2)),
                            'angle': this.getAngle(this.lastObject.x1, this.lastObject.y1, this.lastObject.x2, this.lastObject.y2)
                        })
                    }
                }
                window.canvas.renderAll();
            },
            drawText(color, fsize) {
                const text = new fabric.Textbox('Texto...', {
                    editable: true,
                    fontSize: fsize * scale,
                    fontFamily: 'Verdana',
                    top: window.canvas.height / 2 - 40,
                    left: window.canvas.width / 2 - 40,
                    fill: color,
                    // color: color
                })
                window.canvas.add(text)
                window.canvas.renderAll()
            },
            drawShape(event, shape) {
                let obj;
                const pointer = window.canvas.getPointer(event.e)
                switch(shape.shape) {
                    case 'rect':
                        obj = new fabric.Rect({
                            stroke: shape.color,
                            strokeWidth: shape.width * scale,
                            top: pointer.y,
                            left: pointer.x,
                            x: pointer.x,
                            y: pointer.y,
                            width: 0,
                            height: 0,
                            fill: 'transparent',
                            uuid: new Date().valueOf(),
                            type: 'rect'
                        })
                        break;
                    case 'circ':
                        obj = new fabric.Ellipse({
                            left: pointer.x,
                            top: pointer.y,
                            originX: 'left',
                            originY: 'top',
                            rx: 0,
                            ry: 0,
                            angle: 0,
                            fill: 'transparent',
                            stroke: shape.color,
                            strokeWidth:6,
                            type : 'ellipse',
                            uuid : new Date().valueOf
                        })
                        break;
                    case 'triangle':
                        obj = new fabric.Triangle({
                            left: pointer.x,
                            top: pointer.y,
                            originX: 'center',
                            originY: 'center',
                            pointType: 'arrow_start',
                            x: pointer.x,
                            y: pointer.y,
                            fill: 'transparent',
                            angle: 0,
                            width: 0,
                            height: 0,
                            stroke: shape.color,
                            type: "triangle",
                            uuid: new Date().valueOf(),
                            strokeWidth: shape.width,
                        });
                }
                window.canvas.add(obj)
                this.lastObject = obj
            },
            updateShape(event) {
                const pointer = window.canvas.getPointer(event.e)
                if (this.lastObject) {
                    if (this.lastObject.type == 'triangle') {
                        if(this.lastObject.x > pointer.x){
                            this.lastObject.set({ left: Math.abs(pointer.x) });
                        }
                        if(this.lastObject.x > pointer.y){
                            this.lastObject.set({ top: Math.abs(pointer.y) });
                        }
                        this.lastObject.set({ width: Math.abs(this.lastObject.x - pointer.x) * 2 });
                        this.lastObject.set({ height: Math.abs(this.lastObject.y - pointer.y) * 2 });
                    } else if (this.lastObject.type == 'ellipse') {
                        let rx = Math.abs(this.lastObject.left - pointer.x)/2;
                        let ry = Math.abs(this.lastObject.top - pointer.y)/2;
                        if (rx > this.lastObject.strokeWidth) {
                            rx -= this.lastObject.strokeWidth/2;
                        }
                        if (ry > this.lastObject.strokeWidth) {
                            ry -= this.lastObject.strokeWidth/2;
                        }
                        this.lastObject.set({ rx: rx, ry: ry});
                        this.lastObject.set({originX: this.lastObject.left > pointer.x ? 'right':'left' });
                        this.lastObject.set({originY: this.lastObject.top > pointer.y ? 'bottom':'top' });
                    } else {
                        if(this.lastObject.x > pointer.x){
                            this.lastObject.set({ left: Math.abs(pointer.x) });
                        }
                        if(this.lastObject.y > pointer.y){
                            this.lastObject.set({ top: Math.abs(pointer.y) });
                        }
                        
                        this.lastObject.set({ width: Math.abs(this.lastObject.x - pointer.x) });
                        this.lastObject.set({ height: Math.abs(this.lastObject.y - pointer.y) });
                    }
                } 
                window.canvas.renderAll()
            },
            // mais metodos
            showInfo() { showInfo(this) },
            deleteHandler() { deleteHandler(this) },
            closeModal() {
                this.showModal = false
            },

            // change file
            changeFile(event) {
                if (event.target.files[0]) {
                    this.exercice.img = event.target.files[0]
                    this.img = URL.createObjectURL(event.target.files[0])
                }
            },
            // save exercice
            async save() {
                if (this.isFormation) {
                    if (this.team < 0) {
                        delete(this.exercice.category_id)
                    } else {
                        // prencher defaults
                        this.exercice.category_id = this.category_id
                        this.exercice.professor = this.user.name

                        this.exercice.categoria = this.user.teams[this.team].categories.find((val) => val.id == this.exercice.category_id).name
                        this.exercice.clube = this.user.teams[this.team].name
                        this.exercice.local = this.user.teams[this.team].location
                    }
                }
                save(this) 
            },
            // repeat video
            async play() {
                save(this)
            },


            /* EXERCICIOS EM VIDEO */
            saveFrame() { saveFrame(this) },
            loadFrame() { loadFrame(this) },
            previous() { previous(this) },
            next() { next(this) },
            add() { add(this) },
            del() { del(this) },


            /* SALVAR VIDEO */
            async saveVideo() { _saveVideo(this) }
        },
        computed: {
            categories() {
                // this.exercice.professor = this.user.name
                if (this.user.teams[this.team]) {
                    return this.user.teams[this.team].categories
                }
                return []
            }
        }
    }
</script>

<style lang="scss" scoped>
    .upper-canvas.canvas, canvas {
        width: 630px !important;
        height: 472.5px !important;
        border: 1px solid white;
    }
    .not-allowed {
        cursor: not-allowed;
    }
    .overflow-x-scroll {
        overflow-x: scroll;
    }
    .editable:not(:focus) {
        border: 0px;
        background-color: transparent;
    }
    .editable:focus {
        border: 1px solid gray !important;
    }
    .toggle-fs {
        cursor: pointer;
        position: absolute;
        top: 0.3rem;
        right: 0.3rem;
    }
    pre {
        font-family: inherit !important;
        font-size: inherit;
        line-height: 1;
        margin: 0px;
        color: inherit;
        padding: 0px;
        border: none;
        white-space: pre-line;
        text-align: justify;
        max-height: 100% !important;
        overflow: hidden;
    }
</style>

<style lang="scss">
    .upper-canvas.canvas, canvas, .canvas-container {
        width: 630px !important;
        height: 472.5px !important;
    }
</style>
