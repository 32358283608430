<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('admin.users') }}</h3>
            </div>
            <div class="p-1">
                <router-link class="btn btn-success btn-flat" to="/admin/plans">
                    {{ t('admin.plans-gerir') }}
                </router-link>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <div class="p-0 row p-0 m-0">
                    <div class="form-group col-12 col-sm-8">
                        <label for="key" class="form-label col-12">Chave de acesso mercado pago</label>
                        <input type="text" v-model="access_key" id="key" class="form-control">
                    </div>
                    <div class="form-group col-12 col-sm-4">
                        <label for="key" class="form-label col-12" style="color: transparent">_____</label>
                        <button class="btn btn-primary" @click="envUpdate">Actualizar chave</button>
                    </div>
                </div>
                <data-table v-if="users" class="table table-striped table-bordered" :data="users" :columns="columns"
                    :options="{responsive: true, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th>{{ t('auth.login.email') }}</th>
                            <th>{{ t('auth.signup.name') }}</th>
                            <th>{{ t('general.level') }}</th>
                            <th>{{ t('auth.signup.phone') }}</th>
                            <th>{{ t('profile.status') }}</th>
                            <th>{{ t('profile.end') }}</th>
                            <th>{{ t('general.actions') }}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>

        <!-- Modals do user -->
        <modal-component :classList="['col-10', 'col-md-7', 'col-lg-6', 'col-xlg-5']" v-if="selected" :title="selected.name"
            @hide="() => {this.selected = null}"
            :show="true"
        >
            <form ref="form" @submit.prevent="update" class="row p-1 col-12">
                <div class="p-1 form-group m-0 py-2 col-12" v-for="input in inputs" :key="input.model">
                    <label class="m-0" :for="input.model">{{ input.text }}</label>
                    <input type="date" :name="input.model" v-model="selected.subscription[input.model]" class="form-control">
                </div>

                <div class="p-1 form-group m-0 py-2 col-12">
                    <label class="m-0" for="password">{{ t('auth.changePass.title') }}</label>
                    <input type="password" name="password" v-model="selected.subscription.password" class="form-control">
                </div>

                <div class="text-right">
                    <button class="btn btn-primary">
                        {{ t('treinos.update') }}
                    </button>
                </div>
            </form>
        </modal-component>
        <!-- ./Modals do user -->
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { t } from '@/lang'
    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "FormacoesView",
        components: {
            DataTable,
            ModalComponent,
        },
        data() {
            return {
                users: null,
                user: null,
                selected: null,
                access_key: null,
                password: null,
                columns: [
                    {data: 'id'},
                    {data: 'email'},
                    {data: 'name'},
                    {data: 'level'},
                    {data: 'phone'},
                    {data: null, render: data => `
                        ${data.subscription_active ?
                            "<label class='text-success'>" + t('profile.active') + "</label>" :
                            "<label class='text-danger'>" + t('profile.inactive') + "</label>"
                        }
                    `},
                    {data: null, render: data => `
                        ${data.subscription ?
                            data.subscription.end_subscription: '----'
                        }    
                    `},
                    {data: null, render: data => `
                        <span class='p-1'>
                            <button class='btn btn-warning btn-flat' data-id='${data.id}' data-action='edit'>
                                <i class='fa fa-edit' data-id='${data.id}' data-action='edit'></i>
                            </button>
                        </span>
                        <span class='p-1' ${(data.level == "admin" || data.level == "mod" && this.user.level == "admin") ? "style='display: none;'":""}>
                            <button class='btn btn-success btn-flat' data-id='${data.id}' data-action='up'>
                                <i class='fa fa-arrow-up' data-id='${data.id}' data-action='up'></i>
                            </button>
                        </span>
                        <span class='p-1' ${data.level == "user" ? "style='display: none;'":""}>
                            <button class='btn btn-danger btn-flat' data-id='${data.id}' data-action='down'>
                                <i class='fa fa-arrow-down' data-id='${data.id}' data-action='down'></i>
                            </button>
                        </span>
                        <span class='p-1'>
                            <button class='btn btn-danger btn-flat' data-id='${data.id}' data-action='delete'>
                                <i class='fa fa-trash' data-id='${data.id}' data-action='delete'></i>
                            </button>
                        </span>
                    `}
                ],
                inputs: [
                    {model: 'end_subscription', text: t('admin.end_subscription') },
                    {model: 'last_payment', text: t('admin.last_payment') },
                ]
            }
        },
        created() {
            document.title = t('admin.users')
            this.getUser()
            this.getUsers()
        },
        
        computed: {
            ...mapState(['loading'])
        },
        methods: {
            ...mapActions(['get', 'post', 'put', 'delete']),
            ...mapMutations(['SET_USER', 'SET_LOADING']),
            async envUpdate() {
                this.SET_LOADING(true)
                const res = await this.put({
                    url: 'admin/env/ACCESS_TOKEN',
                    data: {
                        value: this.access_key
                    }
                })
                if (res.status == 200) {
                    this.access_key = null
                }
                alert(res.data.message)
                console.log(res)
                this.SET_LOADING(false)
            },
            async handleClick(event) {
                if (event.target.hasAttribute('data-action')) {
                    const userId = event.target.getAttribute('data-id')
                    let user = null
                    switch(event.target.getAttribute('data-action')) {
                        case 'edit':
                            [user] = this.users.filter(user => user.id == userId)
                            this.selected = Object.assign({}, user)
                            
                            if (!this.selected.subscription) {
                                this.selected.subscription = {}
                            }
                            break
                        case 'up':
                            this.selected = null
                            if (confirm(t('general.do-you-want'))) {
                                await this.update(userId)
                            }
                            break
                        case 'down':
                            this.selected = null
                            if (confirm(t('general.do-you-want'))) {
                                await this.update(userId, true)
                            }
                            break
                        case 'delete':
                            this.selected = null
                            if (confirm(t('general.do-you-want'))) {
                                await this.deleteUser(userId)
                            }
                            break
                    }
                }
            },
            async getUsers() {
                this.SET_LOADING(true)
                const response = await this.get({url: 'admin/users'})
                this.users = response.data.users
                
                if (response.status != 200) {
                    alert(response.data.message)
                    location = '/profile'
                }
                this.SET_LOADING(false)

                if (!this.loading) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }
            },
            async update(id = null, down = false) {
                this.SET_LOADING(true)
                const response = await this.put({
                    url: 'admin/user/' + (this.selected ? this.selected.id:id),
                    data: Object.assign(this.selected ? this.selected.subscription:{}, {down: down, level: true})
                })

                if (response.status == 200) {
                    alert(t("exercices.save-success"))
                } else {
                    alert(response.data.status)
                    console.log(response);
                }
                this.selected = null
                this.SET_LOADING(false)
                this.getUsers()
            },
            async deleteUser(id) {
                this.SET_LOADING(true)
                const response = await this.delete({
                    url: 'user?id=' + id
                })

                if (response.status == 200) {
                    alert(t("general.deleted"))
                } else {
                    alert(response.data.status)
                    console.log(response);
                }
                this.selected = null
                this.SET_LOADING(false)
                this.getUsers()
            },
            async getUser() {
                this.SET_LOADING(true)
                const response = await this.get({url: 'user'})
                if (response.status == 200) {
                    this.user = response.data.user
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)
            },
            t
        }  
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
    .upload-btn {
        bottom: 0px;
        right: 0px;
    }
</style>
