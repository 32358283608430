<template>
    <select-campo-component :esporte="esporte" :show="true" @hide="back" @select="selectCampo"></select-campo-component>
</template>

<script>
    import SelectCampoComponent from '@/components/SelectCampoComponent.vue'
    export default {
        name: 'CampsView',
        components: {
            SelectCampoComponent
        },
        data() {
            return {
                esporte: null,
                video: null
            }
        },
        created() {
            this.esporte = this.$route.query.esporte
            this.video = this.$route.query.video || null
        },
        methods: {
            back() {
                this.$router.push(this.video ? "/videos":"/formacoes")
            },
            selectCampo(id) {
                this.$router.push(
                    `/marcar?esporte=${this.esporte}&camp=${id + 1}${this.video ? "&video=true":""}`
                )
            }
        }
    }
</script>