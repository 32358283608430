import { fabric } from "fabric";
import deleteImg from '@/assets/icons/close.png'
import copyImg from '@/assets/icons/clipboard.png'
import { addShortCuts } from '@/utilities/KeyBoardShortCuts'
import { mouseDown, mouseMove, mouseUp } from '@/utilities/Events.js'

export const scale = 2.031
const renderIcon = (icon) => {
    return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(icon, -size/2, -size/2, size, size);
        ctx.restore();
    }
}

export const deleteObject = () => {
    const els = window.canvas.getActiveObjects()
    els.forEach(x => {
        if (!x.curve) {
            if (x._objects) {
                window.canvas.getObjects().forEach(o => {
                    if (x.uuid == o.uuid) {
                        window.canvas.remove(o)
                    }
                })
            }
            window.canvas.remove(x)
        } else {
            const curve = x.curve
            curve.points.forEach(p => {
                window.canvas.remove(p)
            })
            if (curve.arrow) {
                window.canvas.remove(curve.arrow)
            }
            window.canvas.remove(curve)
        }
    })
    window.canvas.discardActiveObject().renderAll()
    window.canvas.requestRenderAll();
}

export const hideObject = () => {
    const els = window.canvas.getActiveObjects()
    els.forEach(x => {
        if (!x.curve) {
            if (x._objects) {
                window.canvas.getObjects().forEach(o => {
                    if (x.uuid == o.uuid) {
                        o.set("visible", false)
                    }
                })
            }
            x.set('visible', false)
        } else {
            const curve = x.curve
            curve.points.forEach(p => {
                p.set('visible', false)
            })
            if (curve.arrow) {
                curve.arrow.set('visible', false)
            }
            curve.set('visible', false)
        }
    })
    window.canvas.discardActiveObject().renderAll()
    window.canvas.requestRenderAll();
}

export const copyObject = () => {
    let elements = window.canvas.getActiveObjects()
    window.canvas.discardActiveObject().renderAll()

    const curves = []
    elements.forEach(el => {
        let cloned = false
        if (el.curve) {
            curves.forEach(c => {
                if (c.uuid == el.curve.uuid) {
                    cloned = true
                }
            })
            if (!cloned) {
                const path = el.curve.path.copyWithin()
                const curve = new fabric.Path(path, {
                    fill: el.curve.fill, 
                    stroke: el.curve.stroke, 
                    objectCaching: false,
                    strokeWidth: el.curve.strokeWidth,
                    selectable: false,
                    _curve_: true,
                    strokeDashArray: el.curve.strokeDashArray,
                    points: [],
                    controls: {},
                    uuid: new Date().valueOf() + "_" + new Date().toLocaleString()
                });
                curve.path[0][1] += 10
                curve.path[0][2] += 10
                curve.path[1][1] += 10
                curve.path[1][2] += 10
                curve.path[1][3] += 10
                curve.path[1][4] += 10
                window.canvas.add(curve)
                for(let i = 0; i < 3; i++) {
                    const control = createCircControl(curve, i)
                    window.canvas.add(control)
                }
                if (el.curve.arrow) {
                    drawArrow(curve)
                }
            }
        } else {
            el.clone(cloned => {
                if (!cloned.curve) {
                    cloned.set("top", cloned.top + 10)
                    cloned.set("left", cloned.left + 10)
                    window.canvas.add(cloned)
                }
            })
        }
    })
    window.canvas.requestRenderAll();
}

export const getAngle = (x1, y1, x2, y2) => {
    var angle = 0, x, y;
    x = (x2 - x1);
    y = (y2 - y1);
    if (x === 0) {
        angle = (y === 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2;
    } else if (y === 0) {
        angle = (x > 0) ? 0 : Math.PI;
    } else {
        angle = (x < 0) ? Math.atan(y / x) + Math.PI:(y < 0) ? Math.atan(y / x) + (2 * Math.PI) : Math.atan(y / x);
    }
    return (angle * 180 / Math.PI + 90);
}

export const initCanvas = (_this) => {
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = 'yellow';
    fabric.Object.prototype.cornerSize = 20
    fabric.Object.prototype.borderScaleFactor = scale
    window.canvas = new fabric.Canvas('canvas')
    window.canvas.selection = true
    window.canvas.selectionBorderColor = 'blue';
    window.canvas.selectionLineWidth = 5 * scale;
    window.canvas.on('mouse:down', evt => { mouseDown(evt, _this) })
    window.canvas.on('mouse:up', () => { mouseUp(_this) })
    window.canvas.on('mouse:move', (evt, transform) => { mouseMove(evt, transform, _this) })

    window.canvas.requestRenderAll()
    window.canvas.wrapperEl.tabIndex = 1000
    // controladores
    // delete control
    const img = new Image()
    img.src = deleteImg
    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -16 * scale,
        offsetX: 14 * scale,
        cursorStyle: 'pointer',
        mouseUpHandler: deleteObject,
        render: renderIcon(img),
        cornerSize: 20 * scale
    });
    fabric.Textbox.prototype.controls.deleteControl = fabric.Object.prototype.controls.deleteControl
    // copy control
    const copy = new Image()
    copy.src = copyImg
    fabric.Object.prototype.controls.copyControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -16 * scale,
        offsetX: 36 * scale,
        cursorStyle: 'pointer',
        mouseUpHandler: copyObject,
        render: renderIcon(copy),
        cornerSize: 20 * scale
    });
    fabric.Textbox.prototype.controls.copyControl = fabric.Object.prototype.controls.copyControl

    window.canvas.on('path:created', function(e) {
        e.path.set();
        window.canvas.renderAll();
    })
    addShortCuts(window.canvas)
}

export const setBackImage = async (camp, esporte, canvas = window.canvas, call = () => {}) => {
    let img
    img = `/images/${esporte.toUpperCase()}/CAMPOS/PNG/${camp}.png`
    console.log(img)
    let image = new Image()
    image.src = img
    image.onload = async function() {
        var resizedImg = new fabric.Image(image, {
            left: 0,
            top: 0,
            originX: 'left',
            originY: 'top',
            crossOrigin: "anonymous"
        });

        resizedImg.scaleToHeight(canvas.height)
        resizedImg.scaleToWidth(canvas.width)
        // Definir a imagem redimensionada como background
        await canvas.setBackgroundImage(resizedImg, canvas.renderAll.bind(canvas))
        call()
    }
}

export const createCircControl = (curve, point) => {
    const options = [
        [[0,1], [0,2]],
        [[1,1], [1,2]],
        [[1,3], [1,4]],
    ]
    const option = options[point]
    const p = [].concat(curve.path)

    const c = new fabric.Circle({
        fill: curve.stroke,
        top: p[option[1][0]][option[1][1]] - (curve.strokeWidth + 5),
        left: p[option[0][0]][option[0][1]] - (curve.strokeWidth + 5),
        radius: (curve.strokeWidth + 5) * scale,
        curve: curve,
        opacity: 0.3,
        controls: {},
        notAnimate: true,
        x1: null,
        y1: null
    })
    curve.points.push(c)
    curve.notAnimate = true
    if (curve.arrow) {
        curve.arrow.notAnimate = true
    }
    c.on("moving", () => {
        p[option[0][0]][option[0][1]] = c.left + c.radius
        p[option[1][0]][option[1][1]] = c.top + c.radius
        curve.set("path", p)
        if (curve.arrow) {
            curve.arrow.set({
                angle: getAngle(
                    curve.points[1].left,
                    curve.points[1].top,
                    curve.points[2].left,
                    curve.points[2].top,
                ),
                top: curve.points[2].top + c.radius,
                left: curve.points[2].left + c.radius,
            })
        }
        curve.setCoords()
    })
    c.controls.deleteControl = fabric.Object.prototype.controls.deleteControl
    c.controls.copyControl = fabric.Object.prototype.controls.copyControl
    return c
}

export const drawArrow = (curve) => {
    const triangle = new fabric.Triangle({
        left: curve.points[2].left + curve.points[2].radius,
        top: curve.points[2].top + curve.points[2].radius,
        originX: 'center',
        originY: 'center',
        selectable: false,
        angle: getAngle(
            curve.points[1].left,
            curve.points[1].top,
            curve.points[2].left,
            curve.points[2].top,
        ),
        type: (curve.points ? "pathArrow":"triangle"),
        width: (6 + curve.strokeWidth * 1.8) * scale,
        height: (6 + curve.strokeWidth * 1.8) * scale,
        fill: curve.stroke,
        _curve_: true
    });
    curve.arrow = triangle
    window.canvas.add(triangle)
    window.canvas.requestRenderAll()
}
