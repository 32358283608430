<template>
    <div class="col-12 p-4 row justify-content-center">
        <h2 class="title col-12">
            {{ t('treinos.new') }}
        </h2>

        <div class="col-12 col-md-8 card card-outline card-primary p-2" v-if="!loading">
            <form @submit.prevent="submit" class=" row flex-wrap col-12">
                <div class="col-12 p-2">
                    <input v-if="team < 0" type="text" v-model="treino.clube" class="form-control" :placeholder="t('treinos.clube')" required>
                </div>
                <div class="col-12 col-md-7">
                    <div class="p-2 col-12">
                        <label>{{ t('treinos.select_sport') }}</label>
                        <select name="esport" v-model="treino.esporte_id" class="select2 form-control" required>
                            <option v-for="sport in sports" :key="sport.id" :value="sport.id">{{ sport.name }}</option>
                        </select>
                    </div>
                    <div class="p-2 col-12" v-if="team < 0">
                        <input type="text" v-model="treino.professor" class="form-control" required :placeholder="t('treinos.coach')">
                    </div>
                    <div class="p-2 col-12">
                        <template v-if="team >= 0">
                            <label>{{ t('teams.select') }}</label>
                            <select class="form-control select2" v-model="treino.category_id">
                                <option v-for="category in categories" :key="category.id" :value="category.id">
                                    {{ category.name }}
                                </option>
                            </select>
                        </template>
                        <input v-if="team < 0" type="text" v-model="treino.categoria" class="form-control" required :placeholder="t('treinos.category')">
                    </div>
                    <div class="p-2 col-12">
                        <input type="text" v-model="treino.metodologia" class="form-control" required :placeholder="t('treinos.metodology')">
                    </div>
                </div>
                <div class="col-12 col-md-5 position-relative overflow-auto" v-if="team < 0">
                    <input type="file" ref="file" class="d-none" @change="changeFile">
                    <img class="image-responsive responsive col-12" style="min-height: 3rem" :src="img">
                    <button class="btn btn-warning position-absolute text-white bottom-0 right-0" type="button"
                        @click="() => { this.$refs.file.click() }"
                    >
                        <i class="fa fa-upload"></i>
                    </button>
                </div>
                <div class="col-12">
                    <div class="p-2 col-12">
                        <label for="objetivos">{{ t('treinos.date') }}</label>
                        <input type="date" v-model="treino.data" class="form-control" placeholder="Data" required>
                    </div>
                    <div class="col-12 p-2">
                        <label for="objetivos">{{ t('treinos.objectives') }}</label>
                        <textarea class="form-control" rows="4" name="objetivos" v-model="treino.objetivos" maxlength="800"></textarea>
                    </div>
                    <div class="py-2">
                        <button class="btn btn-primary" type="submit">
                            {{ t('treinos.create') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'
    import { t } from '@/lang'

    export default {
        name: 'NewTreinoView',
        data() {
            return {
                user: null,
                team: -1,
                sports: [],
                img: null,
                treino: {
                    name: '',
                    professor: '',
                    categoria: '',
                    metodologia: '',
                    objetivos: '',
                    data: '',
                    img: '',
                    esporte_id: '',
                    category_id: null
                }
            }
        },
        async created() {
            document.title = t('treinos.new')
            await this.getSports()
            await this.getUser()
        },
        computed: {
            ...mapState(['loading']),
            categories() {
                const team = this.user.teams[this.team]
                if (team) { return team.categories }
                return []
            },
        },
        methods: {
            ...mapActions(['get', 'post', 'put', 'delete']),
            ...mapMutations(['SET_LOADING']),
            async getSports() {
                this.SET_LOADING(true)

                const response = await this.get({url: 'sports'})
                if (response.status == 200) {
                    this.sports = response.data.sports
                } else if (response.status == 402) {
                    alert(response.data.message)
                    this.$router.push('/profile')
                } else if (response.status == 401) {
                    this.SET_USER(null)
                    this.$router.push('/login')
                } else {
                    alert(response.data.message)
                }
            },
            async getUser() {
                this.SET_LOADING(true)
                
                const response = await this.get({url: 'user'})
                if (response.status == 200) {
                    this.user = response.data.user
                    console.log(this.user)
                } else if (response.status == 401) {
                    this.SET_USER(null)
                    this.$router.push('/login')
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)
            },
            t,
            changeFile(event) {
                this.treino.img = event.target.files[0]
                this.img = URL.createObjectURL(event.target.files[0])
            },
            async submit() {
                this.SET_LOADING(true)

                const frmDt = new FormData()
                frmDt.append('clube', this.treino.clube)
                frmDt.append('esporte_id', this.treino.esporte_id)
                frmDt.append('professor', this.treino.professor)
                frmDt.append('categoria', this.treino.categoria)
                frmDt.append('metodologia', this.treino.metodologia)
                frmDt.append('data', this.treino.data)
                frmDt.append('objetivos', this.treino.objetivos)
                frmDt.append('img', this.treino.img)

                if (this.treino.category_id && this.team >= 0) {
                    frmDt.delete('clube')
                    frmDt.delete('img')
                    frmDt.append('clube', '----')
                    frmDt.delete('professor')
                    frmDt.append('professor', '----')
                    frmDt.delete('categoria')
                    frmDt.append('categoria', '----')
                    frmDt.append('category_id', this.treino.category_id)
                }

                const response = await this.post({url: 'treinos', formData: frmDt, data: this.treino})
                if (response.status == 200) {
                    alert(t('treinos.created'))
                    this.$router.push('/treino/' + response.data.treino.id)
                } else {
                    alert(response.data.message)
                }
                console.log(response)
                this.SET_LOADING(false)
            }
        },
    }
</script>

<style lang="scss" scoped>
   
</style>
