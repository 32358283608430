<template>
    <div class="modal fade show" :id="id" :class="{'d-flex': show}">
        <div class="d-flex justify-content-center align-items-center col-12">
            <div class="modal-content" :class="classList">
                <div class="modal-header">
                    <h4 class="modal-title">{{ title }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click="() => { this.$emit('hide') }"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <slot></slot>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
    export default {
        name: "ModalComponent",
        props: {
            id: String,
            title: String,
            classList: Array,
            show: Boolean,
        },
        emits: ['hide']
    }
</script>

<style lang="scss" scoped>
    .modal {
        background-color: rgba(8, 10, 9, 0.6);
        
        .modal-content {
            background-color: rgb(240, 242, 245);
            max-height: 95vh !important;
            margin: auto;
            overflow-y: auto;
        }
    }
</style>