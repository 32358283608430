import { createStore } from 'vuex'
import Api from '@/utilities/Api'

export default createStore({
    state: {
        user: true,
        loading: false,
        campo: null,
        authPage: 1
    },
    getters: {
        getUser: (state) => state.user
    },
    mutations: {
        SET_USER(state, data) {
            state.user = data
        },
        SET_CAMPO(state, id) {
            state.campo = id
        },
        SET_LOADING(state, status) {
            state.loading = status
        },
        SET_AUTH(state, auth) {
            state.authPage = auth
        }
    },
    actions: {
        async get(state, { url, type }) {
            state.loading = true
            let response;
            try {
                state.loading = true
                response = await Api.get(url, type)
            } catch(e) {
                response = e
            }
            Api.testLogin(response.status)

            return response
        },
        async post(state, { url, data, formData, type }) {
            let response;
            try {
                state.loading = true
                response = await Api.post(url, data, formData, type)
            } catch(e) {
                response = e
            }
            Api.testLogin(response.status)

            return response
        },
        async delete(state, { url }) {
            let response;
            try {
                state.loading = true
                response = await Api.delete(url)
            } catch(e) {
                response = e
            }
            Api.testLogin(response.status)

            return response
        },
        async put(state, { url, data, formData }) {
            let response;
            try {
                state.loading = true
                response = await Api.put(url, data, formData)
            } catch(e) {
                response = e
            }
            Api.testLogin(response.status)

            return response
        },
    },
    modules: {
    }
})
