<template>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
            </li>
            <li class="nav-item d-none d-sm-inline-block" v-for="item in list" :key="item.url">
                <router-link :to="item.url" class="nav-link">{{ item.name }}</router-link>
            </li>
        </ul>

        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">
            <!-- Notifications Dropdown Menu -->
            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                    {{ t('general.langauge') }}
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <ul class="lang list-unstyled">
                        <li v-for="lang of locales" 
                            :key="lang" @click="() => { 
                                setLanguage(lang.code)
                                this.$router.go()
                            }"
                            :class="{'bg-primary text-light': localStorage.getItem('lang_code') == lang.code}"
                        >{{ lang.name }}</li>
                    </ul>
                </div>
            </li>
            <!-- ./Notifications Dropdown Menu -->
            <!-- Notifications Dropdown Menu -->
            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                    <i class="far fa-user"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <div class="row p-2 m-0">
                        <div class="p-1 col-6">
                            <router-link class="btn btn-flat btn-primary col-12" to="/profile">
                                {{ t('profile.profile') }}
                            </router-link>
                        </div>
                        <div class="p-1 col-6">
                            <button class="btn btn-flat btn-danger col-12" @click="logout">
                                {{ t('profile.exit') }}
                            </button>
                        </div>
                    </div>
                </div>
            </li>
            <!-- ./Notifications Dropdown Menu -->
            <li class="nav-item">
                <a class="nav-link" data-widget="fullscreen" href="#" role="button">
                <i class="fas fa-expand-arrows-alt"></i>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
                <i class="fas fa-th-large"></i>
                </a>
            </li>
        </ul>
    </nav>
    <!-- /.navbar -->
</template>

<script>
    import { t, locales, setLanguage } from '@/lang'
    import { mapMutations, mapActions  } from 'vuex'
    export default {
        name: "NavBar",
        props: {
            list: Array,
        },
        data() {
            return {
                locales: locales,
                localStorage: null,
            }
        },
        created() {
            this.localStorage = localStorage
        },
        methods: {
            t, setLanguage,
            ...mapMutations(['SET_LOADING']),
            ...mapActions(['post']),
            async logout() {
                this.SET_LOADING(true)
                try {
                    const response = await this.post({
                        url: 'auth/logout'
                    })
                    console.log(response)
                    sessionStorage.removeItem("auth_token")
                    location = "/login"
                } catch(e) {
                    alert(e.data.message)
                }
                this.SET_LOADING(false)
            }
        }
    }
</script>

<style scoped>
    ul.lang li {
        padding: 0.3rem;
        cursor: pointer;
    }
    ul.lang li:hover {
        background-color: rgba(190, 200, 220);
    }
</style>