
let exercices = []

export const check = (exercice, all = false) => {
    if (all) {
        if (exercices.length == all.length) {
            // uncheck all
            exercices = []
        } else {
            // check all
            exercices = all
        }
    } else {
        const [exists] = exercices.filter(ex => ex.id == exercice.id)
        if (!exists) {
            exercices.push(exercice)
        } else {
            exercices = exercices.filter(ex => ex.id != exercice.id)
        }
    }
}

// print
export const exercicesToPrint = () => {
    return exercices
}