<template>
    <div class="position-fixed loader login-page">
        <img :src="img">
    </div>
</template>

<script>    
    export default {
        name: "LoaderComponent",
        data() {
            return {
                img: '/images/load.gif'
            }
        }
    }
</script>

<style lang="scss" scoped>
    div.loader {
        background: rgba(200, 200, 202, 0.75);
        width: 100vw;
        height: 100vh;
        z-index: 2000;
        top: 0;
        left: 0;
        text-align: center;
        vertical-align: middle;
        img {
            width: auto;
        }
    }
</style>
