<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('clean.trash') }}</h3>
            </div>
            <div class="p-1">
                <button class="btn btn-danger btn-flat" @click="clean">
                    <i class="fa fa-trash"></i> {{ t("clean.make") }}
                </button>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <data-table v-if="trash" class="table table-striped table-bordered" :data="trash" :columns="columns"
                    :options="{responsive: true, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th>{{ t('clean.name') }}</th>
                            <th>{{ t('teams.type') }}</th>
                            <th>{{ t('clean.owner') }}</th>
                            <th>{{ t('clean.data') }}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { t } from '@/lang'
    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "TeamsViews",
        components: {
            DataTable,
        },
        data() {
            return {
                columns: [
                    {data: 'id'},
                    {data: null, render: data => data.category ? 'Treino da categoria ' + data.category.name:data.user.name},
                    {data: null, render: data => data.category ? 'Biblioteca de clube':'treinos de conta inactiva'},
                    {data: null, render: data => data.user.name},
                    {data: null, render: data => new Date(data.category ? data.created_at:data.end_subscription).toLocaleDateString()},
                ],
                trash: null
            }
        },
        created() {
            document.title = t('clean.clean')
            this.getTrash()
        },
        
        computed: {
            ...mapState(['loading'])
        },
        methods: {
            ...mapActions(['get', 'delete']),
            ...mapMutations(['SET_LOADING']),
            async getTrash() {
                this.SET_LOADING(true)
                const response = await this.get({url: 'admin/clean'})
                console.log(response.data)
                this.trash = response.data.treinos.concat(response.data.subs)
                
                if (response.status != 200) {
                    alert(response.data.message)
                    console.log(response.data)
                    // location = '/profile'
                }
                this.SET_LOADING(false)
            },
            async clean() {
                const go = confirm(t('clean.confirm'))
                if (go) {
                    this.SET_LOADING(true)
                    
                    const response = await this.delete({
                        url: 'admin/clean'
                    })
                    if (response.status >= 200 && response.status < 300) {
                        alert(t("general.deleted"))
                        this.getTrash()
                    } else {
                        console.log(response.data)
                        alert(response.data.status + "\n" + response.data.message)
                    }
                    this.SET_LOADING(false)
                    this.getTrash()
                }
            },
            t
        }  
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
</style>
