<template>
    <div class="row p-0 m-0">
        <div class="col-12 col-sm-3 p-1">
            <span class="d-block">{{ t('general.select_color') }}</span>
            <input type="color" v-model="text.color" class="form-control">
        </div>
        <div class="col-12 col-sm-3 p-1">
            <span class="d-block">{{ t('general.font_size') }}</span>
            <input type="number" min="15" v-model="text.font_size" class="form-control">
        </div>
        <div class="col-12 col-sm-3 p-1">
            <span class="d-block" style="visibility: hidden;">{{ t('arrows.arrow') }}</span>
            <button class="btn btn-warning" @click="add">
                {{ t('datatable.add') }}
            </button>
        </div>
    </div>
</template>

<script>
    import { t } from '@/lang'
    export default {
        name: 'TextComponent',
        emits: ['add'],
        props: {

        },
        data() {
            return {
                text: {
                    color: '#000000',
                    font_size: 25
                }
            }
        },
        methods: {
            t,
            add() {
                this.$emit("add", this.text.color, this.text.font_size)
            }
        }
    }
</script>
