<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('teams.treinos') }}</h3>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <div class="p-2 text-start">
                    {{ t('teams.warn') }}
                </div>
                <data-table v-if="team" class="table table-striped table-bordered" :data="team.treinos" :columns="columns"
                    :options="{responsive: true, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th>{{ t('general.actions') }}</th>
                            <th>{{ t('treinos.coach') }}</th>
                            <th>{{ t('treinos.category') }}</th>
                            <th>{{ t('teams.type') }}</th>
                            <th>{{ t('treinos.date') }}</th>
                            <th>{{ t('exercices.at') }}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>

        <!-- Modals dos exercicios -->
        <modal-component :classList="['col-12 col-md-11 col-lg-10 m-auto', 'p-1']" v-if="view"
            @hide="() => {this.view = null}"
            :show="true"
        >
            <div class="row p-1 bg-dark m-0 mx-auto flex-nowrap" style="overflow-x: auto" ref="fs">
                <div class="col-auto p-1" style="max-width: 30% !important">
                    <div class="col-12 position-relative">
                        <i class="fas fa-expand-arrows-alt position-absolute toggle-fs text-light" @click="fullScreen" title="FullScreen mode"></i>
                        <img :src="view.img_url" class="p-0 m-0" style="max-width: 100px;">
                    </div>
                    <div class="col-12" v-for="label of labels" :key="label.key">
                        <span style="text-transform: uppercase;" class="text-bold d-block">{{ label.label }}:</span>
                        <span class="d-block">{{ view[label.model] }}</span>
                    </div>
                    <div class="col-12">
                        <span style="text-transform: uppercase;" class="text-bold d-block">{{ t('treinos.description') }}:</span>
                        <span class="d-block">
                            <pre>{{ decodeEntities(view['descricao']) }}</pre>
                        </span>
                    </div>
                </div>
                <div class="col-auto" style="width: 630px; height: 472.5px;">
                    <img :src="view.gif" v-if="view.gif" class="p-0 m-0 video-play col-12" ref="video">
                    <video :src="view.video" v-else class="p-0 m-0 video-play col-12" ref="_video" muted autoplay loop>
                        Video not supported, try to download it
                    </video>
                </div>
            </div>
        </modal-component>
        <!-- ./Modals dos exercicios -->
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { t } from '@/lang'
    import { decodeEntities, getExImage } from '@/utilities/HelpFunctions.js'
    import { check } from '@/utilities/print.js'

    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "FormacoesView",
        components: {
            DataTable,
            ModalComponent,
        },
        data() {
            return {
                showModal: false,
                printNow: false,
                onSelect: false,
                team: null,
                columns: [
                    {data: 'id'},
                    {data: null, render: data => `
                        <div class="row p-0 m-0 flex-nowrap">
                            <div class='p-1 m-0 col-auto ${data.type != 'video' ? "d-inline":"d-none "}'>
                                <button class='btn btn-flat btn-primary' data-id='${data.id}' data-action='view'>
                                    <i class='fa fa-eye' data-id='${data.id}' data-action='view'></i>
                                </button>
                            </div>
                            <div class='p-1 m-0 col-auto ${data.type == 'video' ? "d-inline":"d-none "}'>
                                <button class='btn btn-flat btn-primary' data-id='${data.id}' data-action='play'>
                                    <i class='fa fa-play' data-id='${data.id}' data-action='play'></i>
                                </button>
                            </div>
                            <div class='p-1 m-0 col-auto'>
                                <button class='btn btn-flat btn-success' data-id='${data.id}' data-action='download'>
                                    <i class='fa fa-download' data-id='${data.id}' data-action='download'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto'>
                                <button class='btn btn-flat btn-danger' data-id='${data.id}' data-action='delete'>
                                    <i class='fa fa-trash' data-id='${data.id}' data-action='delete'></i>
                                </button>    
                            </div>
                        </div>`
                    },
                    {data: null, render: data => data.user.name},
                    {data: null, render: data => data.category.name},
                    {data: null, render: data => data.type == 'image' ? t('teams.formaction') : (data.type == 'video' ? t('teams.video') : t('teams.treino'))},
                    {data: null, render: data => data.data ? data.data:''},
                    {data: null, render: data => new Date(data.created_at).toLocaleString('af')},
                ],
                labels: [
                    {key: 'professor', model: 'professor', label: t('treinos.prof') },
                    {key: 'clube', model: 'clube', label: t('treinos.clube') },
                    {key: 'categoria', model: 'categoria', label: t('treinos.category') },
                    {key: 'data', model: 'data', label: t('treinos.date') },
                    {key: 'local', model: 'local', label: t('treinos.local') },
                    // {key: 'descricao', model: 'descricao', label: t('treinos.description') },
                ],
                view: null,
            }
        },
        created() {
            document.title = t('teams.treinos')
            this.getExercices()
        },
        
        computed: {
            ...mapState(['loading'])
        },
        methods: {
            ...mapMutations(['SET_LOADING']),
            ...mapActions(['get', 'put', 'delete', 'post']),
            fullScreen() {
                try {
                    if (document.body.requestFullscreen) {
                        if (document.fullscreenElement || document.webkitFullscreenElement) {
                            document.exitFullscreen();
                        } else {
                            this.$refs.fs.requestFullscreen()
                        }
                    } else if (document.body.webkitRequestFullscreen) {
                        if (document.fullscreenElement || document.webkitFullscreenElement) {
                            document.webkitExitFullscreen();
                        } else {
                            this.$refs.fs.webkitRequestFullscreen()
                        }
                    }
                } catch (e) {
                    console.log(e)
                }
            },
            async getExercices() {
                this.SET_LOADING(true)

                const response = await this.get({
                    url: 'teamtreinos'
                })
                
                if (response.status != 200) {
                    alert(response.data.message)
                    // this.$router.push("/teams/treinos")
                } else {
                    this.team = response.data.team
                }
                console.log(response.data)
                this.SET_LOADING(false)
                if (!this.loading && this.$el.querySelector('#my-table')) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }

                check(null, [])
            },
            async handleClick(event) {
                if (event.target.hasAttribute("data-action")) {
                    const id = event.target.getAttribute("data-id")
                    let [exercice] = this.team.treinos.filter(t => t.id == id)
                    switch(event.target.getAttribute("data-action")) {
                        case 'view':
                            // view pdf
                            this.viewPdf(exercice)
                        break
                        case 'play':
                            this.SET_LOADING(true)
                            this.view = Object.assign(exercice, {
                                gif: null,
                                video: null
                            })
                            // formactionlogo
                            // await this.get({url: 'formactionlogo/' + exercice.id, type: 'blob'})
                            this.view.img_url = await getExImage(exercice.id, this.get, 'teamlogo/', 'image/png')
                            
                            // eslint-disable-next-line no-case-declarations
                            const fileType = /(\.gif)$/.exec(this.view.file) ? "image/gif":"video/webm"
                            // eslint-disable-next-line no-case-declarations
                            const rs = await getExImage(exercice.id, this.get, 'teamfile/', fileType)
                            
                            if(fileType != "image/gif") {
                                this.view.gif = null
                                this.view.video = rs
                            } else {
                                this.view.video = null
                                this.view.gif = rs
                            }
                            this.SET_LOADING(false)
                        break
                        case 'download':
                            this.download(exercice)
                        break
                        case 'delete':
                            this.deleteTreino(exercice.id)
                        break
                    }
                }
            },
            async download(exercice) {
                let fileType = "application/pdf"
                if(exercice.type == 'video') {
                    fileType = /(\.gif)$/.exec(exercice.file) ? "image/gif":"video/webm"
                } 
                this.SET_LOADING(true)
                const url = await getExImage(exercice.id, this.get, 'teamfile/', fileType)
                
                const a = document.createElement("a")
                a.setAttribute("download", exercice.user.name + (new Date().toLocaleDateString('af')) + '.' + fileType.replace('octet-stream', 'pdf').replace(/.+\//, ""))
                a.setAttribute("href", url)
                a.setAttribute("target", "_blanck")

                a.click()
                this.SET_LOADING()
            },
            async viewPdf(exercice) {
                let fileType = "application/pdf"
                this.SET_LOADING(true)
                const url = await getExImage(exercice.id, this.get, 'teamfile/', fileType)
                console.log(url)
                window.open(url, '_blanck')
                this.SET_LOADING(false)
            },
            async deleteTreino(id) {
                this.SET_LOADING(true)
                const res = await this.delete({url: "teamtreinos/" + id})
                if (res.status >= 200 && res.status < 300) {
                    alert(t('general.deleted'))
                } else {
                    alert(res.data.message)
                }
                await this.getExercices()
                this.SET_LOADING(false)
            },
            t, decodeEntities,
        }
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
    .upload-btn {
        bottom: 0px;
        right: 0px;
    }
</style>
