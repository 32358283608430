<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('notify.notifys') }}</h3>
            </div>
            <div class="p-1">
                <button class="btn btn-success btn-flat" @click="() => {
                    this.notify.message = ''
                    this.notify.title = ''
                    this.showModal = true
                }">
                    <i class="fa fa-plus"></i> {{ t("datatable.add") }}
                </button>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <data-table v-if="notifys" class="table table-striped table-bordered" :data="notifys" :columns="columns"
                    :options="{responsive: true, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>{{ t('notify.props.order') }}</th>
                            <th>{{ t('notify.props.title') }}</th>
                            <th>{{ t('exercices.at') }}</th>
                            <th>{{ t('general.actions') }}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>

        <modal-component :classList="['col-11', 'col-md-10', 'col-lg-8']" :show="true" v-if="showModal" :title="this.selected ? this.selected.title:t('notify.new')" @hide="() => {
            this.selected = null;
            this.showModal = false;
        }">
            <div class="p-2 row m-0">
                <div class="form-group col-12 col-md-6">
                    <label class='label col-12'>{{ t('notify.props.title') }}</label>
                    <input type='text' name='title' class="form-control" v-model="notify.title"  />
                </div>
                <div class="form-group col-12 col-md-6">
                    <label class='label col-12'>{{ t('notify.props.order') }}</label>
                    <input type='number' min="1" step="1" name='ordem' class="form-control" v-model="notify.ordem"  />
                </div>
                <div class="form-group col-12 col-md-3">
                    <label class='label col-12'>{{ t('notify.props.background') }}</label>
                    <input type='color' name='background' class="form-control" v-model="notify.background"  />
                </div>
                <div class="form-group col-12 col-md-3">
                    <label class='label col-12'>{{ t('notify.props.color') }}</label>
                    <input type='color' name='color' class="form-control" v-model="notify.color"  />
                </div>
                <div class="form-group col-12 col-md-6">
                    <label class='label col-12'>{{ t('notify.props.icon') }}</label>
                    <div class="col-12 row m-0 px-0">
                        <span class="col-10 m-0 py-0 my-0">
                            <select class="form-control" v-model="notify.icon">
                                <option v-for="icon in icons" :key="icon" :value="icon">{{ icon.substring(3) }}</option>
                            </select>
                        </span>
                        <span class="col-2 m-0 py-0 my-0">
                            <i :class="'fa fa-2x ' + notify.icon"></i>
                        </span>
                    </div>
                </div>
                <div class="form-group col-12">
                    <label class='label col-12'>{{ t('notify.props.message') }}</label>
                    <textarea v-model="notify.message" class="form-control"></textarea>
                </div>

                <div class="p-2">
                    <button class='btn btn-warning' @click="update" v-if="selected">
                        {{ t('treinos.update') }}
                    </button>
                    <button class='btn btn-success' @click="save" v-else>
                        {{ t('general.save') }}
                    </button>
                </div>
            </div>
        </modal-component>
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import { mapMutations, mapState, mapActions } from 'vuex'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import { t } from '@/lang'
    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "TeamsViews",
        components: {
            DataTable,
            ModalComponent
        },
        data() {
            return {
                columns: [
                    {data: 'ordem'},
                    {data: 'title'},
                    {data: null, render: data => new Date(data.created_at).toLocaleString()},
                    {data: null, render: data => `
                        <button class='btn btn-warning btn-flat m-1' data-id='${data.id}' data-action='edit'><i class='fa fa-edit' data-id='${data.id}' data-action='edit'></i></button>
                        <button class='btn btn-danger btn-flat m-1' data-id='${data.id}' data-action='delete'><i class='fa fa-trash' data-id='${data.id}' data-action='delete'></i></button>
                    `}
                ],
                notifys: null,
                selected: null,
                notify: {
                    message: 'mensagem',
                    title: '',	
                    ordem: 1,	
                    background: '#eeddff',	
                    color: '#000000',	
                    icon: 'fa-info',
                },
                showModal: false,
                icons: ['fa-info', 'fa-times', 'fa-info-circle', 'fa-home', 'fa-user', 'fa-user-circle', 'fa-check', 'fa-check-circle']
            }
        },
        created() {
            document.title = t('notify.notifys')
            this.getNotifys()
        },
        
        computed: {
            ...mapState(['loading'])
        },
        methods: {
            ...mapActions(['get', 'delete', 'put', 'post']),
            ...mapMutations(['SET_LOADING']),
            async getNotifys() {
                this.SET_LOADING(true)
                const response = await this.get({url: 'admin/notify'})
                console.log(response.data)
                this.notifys = response.data.notifications
                
                if (response.status != 200) {
                    alert(response.data.message)
                    console.log(response.data)
                    // location = '/profile'
                }
                this.SET_LOADING(false)

                if (!this.loading) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }
            },
            t,
            async handleClick(event) {
                if (event.target.hasAttribute('data-action')) {
                    const id = event.target.getAttribute('data-id')
                    let notify = null
                    switch(event.target.getAttribute('data-action')) {
                        case 'edit':
                            [notify] = this.notifys.filter(n => n.id == id)
                            this.notify = Object.assign({}, notify)
                            this.selected = this.notify
                            this.showModal = true
                        break
                        case 'delete':
                            this.selected = null
                            if (confirm(t('general.do-you-want'))) {
                                await this.deleteNotify(id)
                            }
                        break
                    }
                }
            },
            async deleteNotify(id) {
                this.SET_LOADING(true)
                const response = await this.delete({
                    url: 'admin/notify/' + id
                })

                if (response.status == 200) {
                    alert(t("general.deleted"))
                } else {
                    alert(response.data.message)
                    console.log(response);
                }
                this.selected = null
                this.SET_LOADING(false)
                this.getNotifys()
            },
            async update() {
                this.SET_LOADING(true)
                const response = await this.put({
                    url: 'admin/notify/' + this.selected.id,
                    data: this.selected
                })

                if (response.status == 200) {
                    alert(t("general.updated"))
                    this.showModal = false
                    this.selected = null
                } else {
                    console.log(response);
                    alert(response.data.message)
                }
                this.selected = null
                this.SET_LOADING(false)
                this.getNotifys()
            },
            async save() {
                this.SET_LOADING(true)
                const response = await this.post({
                    url: 'admin/notify/',
                    data: this.notify
                })

                if (response.status == 200) {
                    alert(t("exercices.save-success"))
                    this.selected = null
                    this.showModal = false
                } else {
                    console.log(response);
                    alert(response.data.message)
                }
                this.selected = null
                this.SET_LOADING(false)
                this.getNotifys()
            },
        }  
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
</style>
