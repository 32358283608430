import { fabric } from "fabric";
import { DataURIToBlob, fillData } from '@/utilities/HelpFunctions.js'
import { t } from '@/lang'
import { play } from "./VideoFunctions";

/* Eventos */
export const mouseDown = (event, _this) => {
    if (_this.freeDrawing) {
        window.canvas.selection = false;
        window.canvas.wrapperEl.focus();
        _this.$refs.canvas.style.cursor = "pointer"
        switch(_this.selectedOption) {
            case 'arrows':
                if (!_this.objects.line.freemode) {
                    _this.drawArrow(event, _this.objects.line.arrow)
                }
            break
            case 'shapes':
                _this.drawShape(event, _this.objects.shape)
            break
        }
    } else {
        window.canvas.selection = true
    }
}

export const mouseUp = (_this) => {
    window.canvas.selection = true
    window.canvas.isDrawingMode = false
    _this.objects.line.freemode = false
    window.canvas.forEachObject(object => {
        if (!object._curve_) {
            object.setCoords()
            object.selectable = true
        }
    })
    _this.freeDrawing = false

    if (_this.lastObject) {
        const groupObjects = [_this.lastObject]
        if (_this.lastObject.arrow) {
            groupObjects.push(_this.lastObject.arrow)
        }
        // criar grupo
        const group = new fabric.Group(groupObjects, {
            lockScalingFlip : true,
            uuid : _this.lastObject.uuid,
        })
        window.canvas.add(group)
        // delete original elements
        const objs = []
        window.canvas.forEachObject(o => {
            if (o.uuid != group.uuid || o.type == 'group') {
                objs.push(o)
            }
        })
        window.canvas._objects = objs
        window.canvas.requestRenderAll()

        _this.lastObject = null
    }
}

export const mouseMove = (event, transform, _this) => {
    if (_this.freeDrawing) {
        switch(_this.selectedOption) {
            case 'arrows':
                _this.updateArrow(event, transform)
                break;
            case 'shapes':
                _this.updateShape(event, transform)
                break;
            
        }
    } else {
        window.canvas.selection = true
    }
}

// save exercice
export const save = async (_this) => {
    if (!(_this.isFormation && _this.video)) {
        _this.SET_LOADING(true)

        window.canvas.forEachObject(obj => {
            if (obj.curve) {
                window.canvas.remove(obj)
            }
        })
        const path = _this.isFormation ? 'formactions/':'exercicios/'
        const file = DataURIToBlob(window.canvas.toDataURL("image/png"))
        
        const formData = new FormData()
        if (!_this.isFormation) {
            formData.append('img', file, 'image.png')
            formData.append('treino_id', _this.treino.id)
        } else {
            formData.append('file_input', file, 'image.png')
            formData.append('img', _this.exercice.img)
        }
        
        const copy = Object.assign({}, _this.exercice)
        copy.file = "0"
        copy.img = "0"
        copy.type = "image"
        if (fillData(formData, copy)) {
            // send exercice to server
            const send = await _this.post({
                url: path,
                data: formData,
                formData: true
            })
            if (send.status == 200) {
                alert(t('exercices.save-success'))
                if (!_this.isFormation) {
                    _this.$router.push("/treino/" + _this.treino.id)
                } else {
                    _this.$router.push("/formacoes")
                }
            } else {
                alert(send.data.message)
            }
        } else {
            alert(t('exercices.fill-error'))
            _this.showModal = true
        }

        _this.SET_LOADING(false)
    } else {
        _this.saveFrame()
        _this.frame = 1
        _this.loadFrame()
        _this.previw = true
        play(_this)
    }
}
