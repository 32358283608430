<template>
    <div class="row justify-content-start col-12">
        <h3 class="h3 col-12 p-2">{{ t('payment.plans') }}</h3>

        <div class="plan col-12 col-sm-6 col-md-3 p-2 text-center"
            v-for="plan of plans" :key="plan.id"
        >
            <div class="card card-primary elevation-4" style="height: 100%;">
                <div class="card-header p-1">
                    <h4>{{ plan.title }}</h4>
                </div>
                <div class="card-body bg-light">
                    <div class="p-1">
                        {{ plan.short_desc }}
                    </div>
                    <div class="p-1 py-2 text-warning">
                        {{ plan.days }} {{ t('payment.days-of-use') }} <i class="fa fa-check-square"></i>
                    </div>
                    <div class="p-1 py-2 text-success fw-bold" style="font-size: 1.3rem;">
                        R$ {{ String(plan.price).replaceAll(".", ",") }}
                    </div>
                </div>
                <div class="card-footer p-2" style="background-color: #f0f0f0;">
                    <button class="btn btn-primary col-12" @click="pay(plan.id)">
                        {{ t('payment.get') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { t } from '@/lang'
    import { mapActions, mapMutations } from 'vuex';
    import { decodeEntities } from '@/utilities/HelpFunctions.js'

    export default {
        name: "PaymentView",
        data() {
            return {
                plans: null
            }
        },
        async created() {
            document.title = t('payment.plans')
            this.plans = await this.getPlans()
        },
        methods: {
            t, ...mapActions(['get', 'post']),
            ...mapMutations(['SET_LOADING']),
            async getPlans() {
                this.SET_LOADING(true)
                let plans = null;
                const response = await this.get({url: 'plans'})
                if (response.status == 200) {
                    plans = response.data.plans
                    plans.forEach(plan => {
                        plan.title = decodeEntities(plan.title)
                        plan.short_desc = decodeEntities(plan.short_desc)
                    });
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)
                return plans
            },
            async pay(planID) {
                this.SET_LOADING(true)
                const response = await this.get({url: `user/pay?plan_id=${planID}`})
                if (response.status == 200) {
                    console.log(response.data)
                    open(response.data.url)
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)
            }
        }
    }
</script>