import axios from 'axios'

const getToken = () => sessionStorage.getItem("auth_token")
const baseUrl = "https://api.planejadordetreino.com/"
// const baseUrl = "https://api.test.planejadordetreino.com/"
// const baseUrl = "http://localhost:8000/"
// const baseUrl = "http://192.168.43.237:8000/"
// const baseUrl = "http://10.0.2.2:8000/"
export default {
    testLogin(status) {
        switch (status) {
            case 401:
                location = '/login'
                break;
            case 402:
                location = '/payment'
                break;
            default:
                break;
        }
    },
    baseUrl: baseUrl + "api/",
    printUrl: baseUrl + "print",
    url: baseUrl,
    headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`,
    },

    /**
     * Envia uma requisicao get para a api
     * 
     * @param {String} url url da chamada da api
     * @param {String} response tipo de resposta esperada 
     * @returns {Promise} promessa da requisicao axios.get
     */
    get(url, response = 'json') {
        this.headers['Authorization'] = `Bearer ${getToken()}`
        return new Promise((resolve, reject) => {
            axios.get(`${this.baseUrl}${url}`, {
                responseType: response,
                headers: this.headers 
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
        });
    },

    /**
     * Envia uma requisicao post para a api
     * 
     * @param {String} url url da chamada da api
     * @param {JSON} data dados do corpo da requisicao
     * @param {FormData} formData formData com os dados do corpo da requisicao
     * @returns {Promise} promessa da requisicao axios.post
     */
    post(url, data, formData = false, res = 'json') {
        this.headers['Authorization'] = `Bearer ${getToken()}`
        return new Promise((resolve, reject) => {
            if (formData) {
                this.headers['Content-Type'] = 'multipart/form-data'
            }
            axios.post(`${this.baseUrl}${url}`, data, { 
                headers: this.headers,
                responseType: res,
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
        });
    },

    /**
     * Envia uma requisicao put para a api
     * 
     * @param {String} url url da chamada da api
     * @param {JSON} data corpo da requisicao
     * @param {Boolean|FormData} formData formdata com os dados da requisicao
     * @return {Promise} promessa da requisicao axios.put
     */
    put(url, data, formData = false) {
        this.headers['Authorization'] = `Bearer ${getToken()}`
        return new Promise((resolve, reject) => {
            if (formData) {
                this.headers['Content-Type'] = 'multipart/form-data'
            }
            axios.put(`${this.baseUrl}${url}`, data, { headers: this.headers })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
        });
    },

    /**
     * Envia uma requisicao delete para a api
     * 
     * @param {String} url url da rota da api
     * @returns {Promise} promessa da requisicao axios.delete
     */
    delete(url) {
        this.headers['Authorization'] = `Bearer ${getToken()}`
        return new Promise((resolve, reject) => {
            axios.delete(`${this.baseUrl}${url}`, { headers: this.headers })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
        });
    },
}
