import { fillData } from '@/utilities/HelpFunctions.js'
// import CCapture from 'ccapture.js'

const setPropos = (obj, props) => {
    if (!obj._curve_) {
        obj.set("scaleX", props.scaleX)
        obj.set("scaleY", props.scaleY)
        obj.set("left", props.left)
        obj.set("top", props.top)
        obj.set("angle", props.angle)
    }
    obj.canvas.renderAll.bind(obj.canvas)
}

export const runAnimation = (obj, count, time, call) => {
    const animationKey = `f-${count}`
    if (obj._animations.start == count) {
        // setar props
        const _props = obj._animations[`f-${count}`]
        obj.visible = _props.visible
        if (_props.path) {
            obj.set("path", _props.path)
        }
        setPropos(obj, _props)
    }
    let animationProps = {
        left: -1000
    }
    if (obj._animations[animationKey]) {
        animationProps = Object.assign({}, obj._animations[animationKey])
        obj.set("visible", animationProps.visible)
        if (animationProps.path) {
            obj.set("path", animationProps.path)
            delete animationProps.path
        }
        obj.canvas.requestRenderAll()
        delete animationProps.visible
    }

    if (obj.curve && obj.curve.points) {
        obj.set("visible", false)
    } else if (obj.type == 'arrowPath') {
        animationProps = {
            scaleX: obj.scaleX
        }
    }
    obj.animate(animationProps, {
        easing: undefined,
        duration: time,
        onChange() {
            obj.canvas.renderAll.bind(obj.canvas)();
        },
        onComplete() {
            if (obj._animations.start <= count && !obj._animations[`f-${count + 1}`]) {
                call()
            } else {
                runAnimation(obj, count + 1, time, call);
            }
        }
    })
}

export const previwVideo = async (canvas, time = 1, save) => {
    window.sc = canvas
    return new Promise(resolve => {
        time = 1 / time
        time *= 1000
        // salvar o frame actual
        save()

        // run animations
        let count = 0;
        // window.video = null
        canvas.requestRenderAll()
        const recorder = new window.MediaRecorder(canvas.getElement().captureStream(), {
            mimeType: 'video/webm'
        })
        window.chuncks = []
        recorder.ondataavailable = e => {
            window.chuncks.push(e.data)
        }
        // recorder.start()
        if (canvas.getObjects().length == 0) {
            resolve()
            alert("Adicione pelo menos um objecto")
        } else {
            // const recorder = window.RecordRTC(canvas.getElement(), {
            //     disableLogs:true,
            //     type: 'canvas'
            // })
            // recorder.startRecording()
            recorder.start()
            canvas.getObjects().map((obj) => {
                // setar props inicias e comecar animacoes a partir do 1
                if (obj._animations.start == 1 && !obj.notAnimate) {
                    // setar props
                    const _props = obj._animations[`f-1`]
                    obj.visible = _props.visible
                    setPropos(obj, _props)
                }
                // rodar da primeira animacao
                runAnimation(obj, 1, time, function() {
                    count++;
                    if (count >= canvas.getObjects().length) {
                        setTimeout(() => {
                            // recorder.stopRecording(function() {
                                //     window.video = recorder.getBlob();
                                //     resolve();
                                // })
                            recorder.stop()
                            resolve()
                        }, time);
                    }
                })
            })
        }
    })
}

// export const previwVideo_old = async (canvas, time = 1, save, frames) => {
//     time = 1 / time
//     time *= 1000
//     const frame = 1
//     // salvar o frame actual
//     save()
//     return new Promise(resolve =>  {
//         canvas.forEachObject(obj => {
//             // eslint-disable-next-line no-prototype-builtins
//             if (obj._animations.hasOwnProperty(`f-${frame}`) && obj._animations.start <= frame) {
//                 // setar props
//                 const _props = obj._animations[`f-${frame}`]
//                 obj.visible = _props.visible
//                 if (!obj._curve_) {
//                     obj.scaleX = _props.scaleX
//                     obj.scaleY = _props.scaleY
//                     obj.left = _props.left
//                     obj.top = _props.top
//                     obj.angle = _props.angle
//                 }
//             } else {
//                 // esconder obj
//                 obj.visible = false
//             }
//             obj.canvas.renderAll.bind(obj.canvas)
//         })
//         // canvas.discardActiveObject()
//         canvas.requestRenderAll()
        
//         // iniciar animacoes
//         let count = frame;
//         let t = setInterval(() => {
//             count++
//             if (count < frames) {
//                 canvas.forEachObject(obj => {
//                     // eslint-disable-next-line no-prototype-builtins
//                     if (obj._animations.hasOwnProperty(`f-${count}`) && obj._animations.start <= count) {
//                         if (count == obj._animations.start) {
//                             // mover para posicao inicial
//                             obj.set(obj._animations[`f-${count}`])
//                         }
//                         // animar
//                         obj.visible = obj._animations[`f-${count}`].visible
//                         canvas.requestRenderAll()

//                         const copy = Object.assign({}, obj._animations[`f-${count}`])
//                         delete copy.visible
//                         if (!obj._curve_) {
//                             obj.animate(copy, {
//                                 easing: undefined,
//                                 duration: time,
//                                 onChange: obj.canvas.renderAll.bind(obj.canvas),
//                                 onOmplete: null
//                             })
//                         }
//                     } else {
//                         // esconder objeto
//                         obj.visible = false
//                     }
//                 })
//             } else {
//                 clearInterval(t)
//                 // isRecording = false
//                 resolve("Previw concluido")
//             }
//         }, time);
//     })
// }

export const sendVideo = async (images, formData, post) => {
    images = window.chuncks
    const blob = new Blob(images)
   
    if (!blob) {
        return null
    }
    formData.append("file_input", blob, new Date().valueOf() + '.webm')
    const res = await post({
        url: 'formactions/',
        formData: true,
        data: formData
    })
    return res
}
export const saveVideo = async (ex, post/*, canvas, time*/) => {
    // return new Promise(resolve => {
        // const chunks = []
        const formData = new FormData()

        formData.append("img", ex.img)
        delete ex.img
        delete ex.file
        if (!fillData(formData, ex)) {
            return null
        } else {
            formData.append('type', "video");

            // const recorder = new window.MediaRecorder(canvas.getElement().captureStream(), {mimeType: 'video/webm'})

            // recorder.ondataavailable = function(e) {
            //     chunks.push(e.data);
            // }
            // recorder.start(time/60);

            // previwVideo(canvas, time, () => {}).then(async () => {
            //     recorder.stop()
            //     const send = await sendVideo(chunks, formData, post)
            //     resolve(send)
            // })
            // (async function() {
            //     const send = await sendVideo(window.video, formData, post)
            //     resolve(send)
            // });
            return await sendVideo(window.video, formData, post)
        }
    // })
}
