<template>
    <div class="col-12 row p-3">
        <div class="col-12 col-sm-6 col-md-5 p-2" v-if="user">
            <table class="table table-striped col-12">
                <thead>
                    <tr>
                        <th colspan="2">{{ t('profile.signature') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ t("profile.status") }}</td>
                        <td>{{ user.subscription_active ? t("profile.active"):t("profile.inactive") }}</td>
                    </tr>
                    <tr>
                        <td>{{ t("profile.end") }}</td>
                        <td>{{ user.subscription ? user.subscription.end_subscription:t("profile.nosign") }}</td>
                    </tr>
                    <tr>
                        <td>{{ t("profile.last") }}</td>
                        <td>{{ user.subscription ? user.subscription.last_payment:t("profile.nosign") }}</td>
                    </tr>
                </tbody>
            </table>
            <router-link class="btn btn-primary" to="/payment">
                {{ t("profile.plans") }}
            </router-link>
        </div>
        <div class="col-12 col-sm-6 p-2" v-if="user">
            <form @submit.prevent="update">
                <table class="col-12 table">
                    <thead>
                        <tr>
                            <th colspan="2">{{ t('profile.my') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="input of inputs" :key="input.key">
                            <td>
                                <label>{{ input.label }}</label>
                            </td>
                            <td>
                                <input :type="input.type" v-model="user[input.key]" class="form-control">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type="submit" class="btn btn-primary m-1">
                    {{ t('treinos.update') }}
                </button>
                <router-link to="/clubes" type="submit" class="btn btn-primary m-1">
                    Meus clubes
                </router-link>
                <router-link class="btn btn-success m-1" v-if="user.level == 'admin' || user.level == 'master'" to="/admin/users">
                    {{ t('admin.admin-area') }}
                </router-link>
            </form>
        </div>
    </div>
</template>

<script>
    import { t } from '@/lang'
    import { mapActions, mapMutations } from 'vuex';

    export default {
        name: "ProfileView",
        data() {
            return {
                user: null,
                inputs: [
                    {label: t('auth.signup.name'), key: 'name', type: 'text'},
                    {label: t('auth.login.email'), key: 'email', type: 'email'},
                    {label: t('auth.signup.phone'), key: 'phone', type: 'number'},
                    {label: t('auth.login.password'), key: 'password', type: 'password'},
                ]
            }
        },
        created() {
            this.getUser()
        },
        methods: {
            t, ...mapActions(['get', 'post', 'put']),
            ...mapMutations(['SET_LOADING']),
            async getUser() {
                this.SET_LOADING(true)
                const response = await this.get({url: 'user'})
                console.log(response.data)
                if (response.status == 200) {
                    this.user = response.data.user
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)
            },
            async update() {
                this.SET_LOADING(true)
                const up = await this.put({
                    url: 'user',
                    data: this.user
                })
                if (up.status >= 200 && up.status <= 299) {
                    alert(t('general.done'))
                } else {
                    alert(up.data.message)
                }
                this.SET_LOADING(false)
            }
        },
    }
</script>
