import ptBr from './locales/pt-br.json'
import esEs from './locales/es-es.json'
import enUs from './locales/en-us.json'

export const locales = [
    ptBr,
    esEs,
    enUs
]

const key = "lang_code"

export const setLanguage = code => {
    let exists = false
    locales.forEach(lang => {
        if (lang.code == code) {
            exists = true
        }
    })
    if (exists) {
        localStorage.setItem(key, code)
    } else if(locales.length >= 1) {
        localStorage.setItem(key, locales[0].code)
    }
}

export const t = (multiLevelKey, dflt = '') => {
    let langCode = localStorage.getItem(key)
    if (!langCode) {
        langCode = locales[0].code
    }
    for (const locale of locales) {
        if (locale.code == langCode) {
            multiLevelKey = multiLevelKey.split('.')
            
            let message = locale.translate
            for (const tKey of multiLevelKey) {
                if (message[tKey]) {
                    message = message[tKey]
                } else {
                    return dflt
                }
            }
            return message
        }
    }
    return dflt
}
