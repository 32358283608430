<template>
    <div class="row p-0 m-0" @submit.prevent="send">
        <div class="col-12 col-sm-2 p-1">
            <span class="d-block">{{ t('general.select_color') }}</span>
            <input type="color" v-model="line.color" class="form-control" @change="send">
        </div>
        <div class="col-12 col-sm-2 p-1">
            <span class="d-block">{{ t('arrows.width') }}</span>
            <input type="number" min="3" max="20" v-model="line.width" class="form-control" @change="send" required>
        </div>
        <div class="col-12 col-sm-2 p-1">
            <span class="d-block" style="visibility: hidden;">{{ t('arrows.dashed') }}</span>
            <div class="icheck-primary d-inline">
                <input type="checkbox" id="checkboxPrimary1" v-model="line.dashed" @change="send">
                <label for="checkboxPrimary1">
                </label>
            </div>
            <span class="d-inline">{{ t('arrows.dashed') }}</span>
        </div>
        <div class="col-12 col-sm-2 p-1">
            <span class="d-block" style="visibility: hidden;">{{ t('arrows.arrow') }}</span>
            <div class="icheck-primary d-inline">
                <input type="checkbox" id="checkboxPrimary2" v-model="line.arrow" @change="send">
                <label for="checkboxPrimary2">
                </label>
            </div>
            <span class="d-inline">{{ t('arrows.arrow') }}</span>
        </div>
        <div class="col-12 col-sm-2 p-1">
            <span class="d-block" style="visibility: hidden;">{{ t('arrows.free') }}</span>
            <div class="icheck-primary d-inline">
                <input type="checkbox" id="checkboxPrimary3" v-model="line.freemode" @change="send">
                <label for="checkboxPrimary3">
                </label>
            </div>
            <span class="d-inline">{{ t('arrows.free') }}</span>
        </div>
        <div class="col-12 col-sm-2 p-1">
            <span class="d-block" style="visibility: hidden;">{{ t('arrows.arc') }}</span>
            <div class="d-inline">
                <button class="col-12 btn btn-warning" @click="addCurve">
                    <i class="fa fa-plus"></i>
                    {{ t('arrows.arc') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { t } from '@/lang'
    import { fabric } from 'fabric'
    import { createCircControl, drawArrow } from '@/utilities/DrawFunctions'
    
    export default {
        name: 'LinesComponent',
        emits: ['submit'],
        props: {

        },
        data() {
            return {
                line: {
                    dashed: false,
                    width: 3,
                    color: '#ff0000',
                    arrow: false,
                    freemode: false,
                    arc: false
                }
            }
        },
        created() {
            window.canvas.on('object:scaling', function(options) {
                if (options.target.curve) {
                    console.log(options.target)
                    options.target.set({
                        scaleX: 1,
                        scaleY: 1,
                    });
                }
            });
        },
        methods: {
            t,
            send() {
                if (this.line.freemode) {
                    this.line.dashed = false
                    this.line.arrow = false
                    this.line.arc = false
                    window.canvas.isDrawingMode = true;
                    window.canvas.freeDrawingBrush.width = this.line.width;
                    window.canvas.freeDrawingBrush.color = this.line.color;
                }
                this.$emit("submit", this.line)
            },
            createCircControl,
            addCurve() {
                const curve = new fabric.Path('M 65 200 Q 150, 100, 300, 200', {
                    fill: '', 
                    stroke: this.line.color, 
                    objectCaching: false,
                    strokeWidth: this.line.width,
                    selectable: false,
                    _curve_: true,
                    strokeDashArray: this.line.dashed ? [this.line.width * 1.7, this.line.width * 1.7] : [0,0],
                    points: [],
                    controls: {},
                    uuid: new Date().valueOf() + "_" + new Date().toLocaleString()
                });
                window.canvas.add(curve)
                for(let i = 0; i < 3; i++) {
                    const control = this.createCircControl(curve, i)
                    window.canvas.add(control)
                }
                if (this.line.arrow) {
                    drawArrow(curve)
                }
            },
        }
    }
</script>
