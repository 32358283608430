<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('teams.categories') }}</h3>
            </div>
            <div class="p-1">
                <button class="btn btn-warning btn-flat" @click="() => this.show = true">
                    <i class="fa fa-plus"></i> {{ t("datatable.add") }}
                </button>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <data-table v-if="team" class="table table-striped table-bordered" :data="team.categories" :columns="columns"
                    :options="{responsive: true, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th>{{ t('auth.signup.name') }}</th>
                            <th>{{ t('treinos.description') }}</th>
                            <th>{{ t('exercices.at') }}</th>
                            <th></th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>

        <!-- Modals do plano -->
        <modal-component :classList="['col-10', 'col-sm-8', 'col-md-7', 'col-lg-5', 'col-xlg-5']" v-if="show" :title="t('teams.addc')"
            @hide="() => {this.show = false}"
            :show="true"
        >
            <form ref="form" @submit.prevent="submit" class="row p-1 col-12">
                <div class="p-1 form-group m-0 col-12">
                    <label>{{ t('auth.signup.name')}}</label>
                    <input type="text" required name="name" v-model="category.name" class="form-control" />
                </div>
                <div class="p-1 form-group m-0 col-12">
                    <label>{{ t('treinos.description')}}</label>
                    <textarea maxlength="150" type="text" required name="description" v-model="category.description" class="form-control"></textarea>
                </div>

                <div class="p-1">
                    <button class="btn btn-primary">
                        {{ t('datatable.add') }}
                    </button>
                </div>
            </form>
        </modal-component>
        <!-- ./Modals do plano -->

        <!-- Modals do plano -->
        <modal-component :classList="['col-10', 'col-sm-8', 'col-md-7', 'col-lg-5', 'col-xlg-5']" v-if="selected" :title="selected.name"
            @hide="() => {this.selected = false}"
            :show="true"
        >
            <form ref="form" @submit.prevent="updateCategory" class="row p-1 col-12">
                <div class="p-1 form-group m-0 col-12">
                    <label>{{ t('auth.signup.name')}}</label>
                    <input type="text" required name="name" v-model="selected.name" class="form-control" />
                </div>
                <div class="p-1 form-group m-0 col-12">
                    <label>{{ t('treinos.description')}}</label>
                    <textarea maxlength="150" type="text" required name="description" v-model="selected.description" class="form-control"></textarea>
                </div>

                <div class="p-1">
                    <button class="btn btn-primary">
                        {{ t('treinos.update') }}
                    </button>
                </div>
            </form>
        </modal-component>
        <!-- ./Modals do plano -->
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { t } from '@/lang'
    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "PlansView",
        components: {
            DataTable,
            ModalComponent,
        },
        data() {
            return {
                team: null,
                email: '',
                show: false,
                category: {
                    name: null,
                    description: null
                },
                selected: null,
                columns: [
                    {data: 'id'},
                    {data: 'name'},
                    {data: 'description'},
                    {data: null, render: data =>
                        new Date(data.created_at).toLocaleString('af')
                    },
                    {data: null, render: data => `
                        <button class='btn btn-flat btn-warning' data-id='${data.id}' data-action='edit'>
                            <i class='fa fa-edit' data-id='${data.id}' data-action='edit'></i>    
                        </button>    
                        <button class='btn btn-flat btn-danger' data-id='${data.id}' data-action='delete'>
                            <i class='fa fa-trash' data-id='${data.id}' data-action='delete'></i>    
                        </button>    
                    `}
                ],
            }
        },
        async created() {
            document.title = t('teams.categories')
            await this.getTeam()
        },
        
        computed: {
            ...mapState(['loading'])
        },
        methods: {
            ...mapActions(['get', 'post', 'put', 'delete']),
            ...mapMutations(['SET_LOADING']),
            async handleClick(event) {
                if (event.target.hasAttribute('data-action')) {
                    
                    const planID = event.target.getAttribute('data-id')
                    const c = this.team.categories.find(category => category.id == planID)
                    switch(event.target.getAttribute('data-action')) {
                        case 'delete':
                            this.deleteCategory(planID)
                        break
                        case 'edit':
                            this.selected = c
                        break
                    }
                }
            },
            async submit() {
                this.SET_LOADING(true)
                const res = await this.post({ url: "team/categories", data: this.category })
                if (res.status >= 200 && res.status < 300) {
                    alert(t('general.done'))
                    this.show = false
                    console.log(res.data.team)
                } else if (res.status == 422) {
                    alert(t('general.422'))
                    if (res.data.erros) {
                        for(let f in res.data.erros) {
                            alert(f[0])
                        }
                    }
                } else {
                    alert(res.data.message)
                }
                await this.getTeam()
            },
            async getTeam() {
                this.SET_LOADING(true)
                const res = await this.get({ url: "team/categories" })
                if (res.status == 200) {
                    this.team = res.data.team
                    console.log(res.data.team)
                } else {
                    alert(res.data.message)
                }
                this.SET_LOADING(false)
                if (!this.loading) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }
            },
            async deleteCategory(id) {
                if (confirm(t('general.do-you-want'))) {
                    this.SET_LOADING(true)
                    const res = await this.delete({ url: "team/categories/" + id })
                    if (res.status == 200) {
                        console.log(res.data)
                        alert(t('general.deleted'))
                        this.getTeam()
                    } else {
                        alert(res.data.message)
                    }
                    console.log(res.data)
                    this.SET_LOADING(false)
                }
            },
            async updateCategory() {
                this.SET_LOADING(true)
                const res = await this.put({ url: "team/categories/" + this.selected.id, data: this.selected })
                if (res.status == 200) {
                    console.log(res.data)
                    alert(t('general.updated'))
                    this.getTeam()
                } else {
                    alert(res.data.message)
                }
                console.log(res.data)
                this.SET_LOADING(false)
            },
            t
        }  
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
</style>
