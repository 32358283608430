<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('teams.teams') }}</h3>
            </div>
            <div class="p-1">
                <button class="btn btn-warning btn-flat" @click="() => this.$router.push('/admin/new-team')">
                    <i class="fa fa-plus"></i> {{ t("datatable.add") }}
                </button>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <data-table v-if="teams" class="table table-striped table-bordered" :data="teams" :columns="columns"
                    :options="{responsive: true, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th>{{ t('teams.team') }}</th>
                            <th>{{ t('teams.leader') }}</th>
                            <th>{{ t('auth.login.email') }}</th>
                            <th>{{ t('teams.max_users') }}</th>
                            <th>{{ t('teams.max_categories') }}</th>
                            <th>{{ t('teams.end_subscription') }}</th>
                            <th>{{ t('general.actions')}}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>

        <!-- Modals do plano -->
        <modal-component :classList="['col-10', 'col-sm-8', 'col-md-7', 'col-lg-5', 'col-xlg-5']" v-if="selected" :title="selected.name"
            @hide="() => {this.selected = null}"
            :show="true"
        >
            <form ref="form" @submit.prevent="update" class="row p-1 col-12">
                <div class="p-1 form-group m-0 py-0 col-12">
                    <span class="d-block">{{ t('teams.leader') }}</span>
                    <div class="form-control">{{ selected.user.name }}</div>
                </div>
                <div class="p-1 form-group m-0 py-2 col-12" v-for="input in inputs" :key="input.model">
                    <label class="m-0" :for="input.model">{{ input.text }}</label>
                    <input :type="input.type" min="1" :name="input.model" v-model="selected[input.model]" class="form-control" required>
                </div>

                <div class="text-right p-1 py-2">
                    <button class="btn btn-primary">
                        {{ t('treinos.update') }}
                    </button>
                </div>
            </form>
        </modal-component>
        <!-- ./Modals do plano -->
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { t } from '@/lang'
    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "TeamsViews",
        components: {
            DataTable,
            ModalComponent,
        },
        data() {
            return {
                teams: null,
                selected: null,
                selectedRef: null,
                user: null,
                newPlan: {
                    title: '',
                    days: 30,
                    price: 0,
                    short_desc: '',
                    show: false,
                },
                columns: [
                    {data: 'id'},
                    {data: 'name'},
                    {data: null, render: data => data.user.name},
                    {data: null, render: data => data.user.email},
                    {data: 'max_users'},
                    {data: 'max_categories'},
                    {data: 'end_subscription'},
                    {data: null, render: data => `
                        <span class='p-1'>
                            <button class='btn btn-warning btn-flat' data-id='${data.id}' data-action='edit'>
                                <i class='fa fa-edit' data-id='${data.id}' data-action='edit'></i>
                            </button>
                            <button class='btn btn-danger btn-flat' data-id='${data.id}' data-action='delete'>
                                <i class='fa fa-trash' data-id='${data.id}' data-action='delete'></i>
                            </button>
                        </span>
                    `}
                ],
                inputs: [
                    {model: 'max_users', text: t('teams.max_users'), type: 'number'},
                    {model: 'max_categories', text: t('teams.max_categories'), type: 'number' },
                    {model: 'end_subscription', text: t('teams.end_subscription'), type: 'date'},
                ]
            }
        },
        created() {
            document.title = t('teams.teams')
            this.getUser()
            this.getTeams()
        },
        
        computed: {
            ...mapState(['loading'])
        },
        methods: {
            ...mapActions(['get', 'post', 'put', 'delete']),
            ...mapMutations(['SET_LOADING']),
            async handleClick(event) {
                if (event.target.hasAttribute('data-action')) {
                    const teamID = event.target.getAttribute('data-id')
                    let team = null
                    switch(event.target.getAttribute('data-action')) {
                        case 'edit':
                            [team] = this.teams.filter(team => team.id == teamID)
                            this.selectedRef = team
                            this.selected = Object.assign({}, team)
                        break
                        case 'delete':
                            // [team] = this.teams.filter(team => team.id == teamID)
                            if (confirm(t('general.do-you-want'))) {
                                this.deleteTeam(teamID)
                            }
                        break
                    }
                }
            },
            async getTeams() {
                this.SET_LOADING(true)
                const response = await this.get({url: 'admin/teams'})
                this.teams = response.data.teams
                
                if (response.status != 200) {
                    alert(response.data.message)
                    location = '/profile'
                }
                this.SET_LOADING(false)

                if (!this.loading) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }
            },
            async deleteTeam(id) {
                this.SET_LOADING(true)
                
                const response = await this.delete({
                    url: 'admin/teams/' + id
                })
                if (response.status >= 200 && response.status < 300) {
                    alert(t("general.deleted"))
                    this.getTeams()
                } else {
                    alert(response.data.status + "\n" + response.data.message)
                    console.log(response.data)
                }
                this.SET_LOADING(false)
            },
            async update() {
                this.SET_LOADING(true)
                this.selected.end_subscription = (new Date(this.selected.end_subscription)).toLocaleDateString("af")
                
                const response = await this.put({
                    url: 'admin/teams/' + this.selected.id,
                    data: this.selected
                })

                if (response.status >= 200 && response.status < 300) {
                    alert(t("general.updated"))
                    this.selectedRef = Object.assign(this.selectedRef, this.selected)
                    this.selected = null
                } else {
                    alert(response.data.status + "\n" + response.data.message)
                    console.log(response.data)
                }
                this.SET_LOADING(false)
            },
            async getUser() { 
                this.SET_LOADING(true)
                const response = await this.get({url: 'user'})
                if (response.status == 200) {
                    this.user = response.data.user
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)
            },
            t
        }  
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
</style>
