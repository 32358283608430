import { t } from '@/lang'
import { saveVideo, previwVideo } from '@/utilities/VideoHelper.js'


/* EXERCICIOS EM VIDEO */
export const saveFrame = (_this) => {
    window.canvas.selection = false
    window.canvas.discardActiveObject()
    window.canvas.requestRenderAll()
    window.canvas.forEachObject(obj => {
        if (!obj._animations) {
            obj._animations = {
                start: _this.frame
            }
        }
        if (obj._animations.start <= _this.frame) {
            // obj._animations[`f-${_this.frame}`] = {
            //     'visible': obj.visible
            // }
            obj._animations[`f-${_this.frame}`] = {
                'scaleX': obj.scaleX,
                'scaleY': obj.scaleY,
                'angle': obj.angle,
                'left': obj.left,
                'top': obj.top,
                'visible': obj.visible
            }
            if (obj.notAnimate && obj.type == 'path') {
                obj._animations[`f-${_this.frame}`].path = obj.path
            }
        }
        obj.canvas.renderAll.bind(obj.canvas)
    })
}

export const loadFrame = (_this) => {
    const frame = _this.frame
    window.canvas.forEachObject(obj => {
        if (!obj._animations) {
            obj._animations = {
                start: frame
            }
        }
        // eslint-disable-next-line no-prototype-builtins
        if (obj._animations.hasOwnProperty(`f-${frame}`) && obj._animations.start <= frame) {
            // setar props
            const _props = obj._animations[`f-${frame}`]
            obj.visible = _props.visible
            if (!obj._curve_ && !obj.curve) {
                obj.scaleX = _props.scaleX
                obj.scaleY = _props.scaleY
                obj.left = _props.left
                obj.top = _props.top
                obj.angle = _props.angle
            }
        } else {
            // esconder obj
            obj.visible = false
        }
        obj.canvas.renderAll.bind(obj.canvas)
    })
    window.canvas.requestRenderAll()
}

export const previous = (_this) => {
    _this.saveFrame()
    if(_this.frame > 1) {
        _this.frame--
        // load the canvas
        _this.loadFrame(/*_this.frames[this.frame - 1]*/)

        return true
    }
}

export const next = (_this) => {
    _this.saveFrame()
    if(_this.frame < _this.frames) {
        _this.frame++
        // load the canvas
        _this.loadFrame(/*_this.frames[_this.frame - 1]*/)
        return true
    } else {
        alert(t('general.add-frame'))
    }
}

export const add = (_this) => {
    _this.saveFrame()
    // copiar o ultimo frame
    _this.frame = _this.frames
    _this.loadFrame()
    _this.frames++
    _this.frame = _this.frames
    _this.saveFrame()
    _this.loadFrame(/*_this.frames[_this.frame - 1]*/)
}

export const del = (_this) => {
    if (_this.frames > 1) {
        window.canvas.forEachObject(obj => {
            if (obj._animations && obj._animations.start >= _this.frames && _this.frame == _this.frames) {
                window.canvas.remove(obj)
            } else {
                for(let i = _this.frame; i < _this.frames; i++) {
                    if (obj._animations[`f-${i + 1}`]) {
                        obj._animations[`f-${i}`] = Object.assign({}, obj._animations[`f-${i + 1}`])
                    }
                }
                obj._animations.start--
                if (obj._animations[`f-${_this.frame}`]) {
                    const _props = Object.assign({}, obj._animations[`f-${_this.frame}`])
                    obj.visible = _props.visible
                    if (!obj._curve_ && !obj.curve) {
                        obj.set('scaleX', _props.scaleX)
                        obj.set('scaleY', _props.scaleY)
                        obj.set('left', _props.left)
                        obj.set('top', _props.top)
                        obj.set('angle', _props.angle)
                    }
                    delete obj._animations[`f-${_this.frames}`]
                } else {
                    obj.set("visible", false)
                }
                obj.canvas.renderAll.bind(obj.canvas)
            }
        })
        // eliminar o frame
        _this.frames--
        if (!_this.previous()) {
            _this.next();
        }
    }
}

/* SALVAR VIDEO */
export const _saveVideo = async (_this) => {
    _this.SET_LOADING(true)
    const copy = Object.assign({}, _this.exercice)
    const save = await saveVideo(copy, _this.post/*, window.canvas, _this.speed, _this.frames */)//.then(save => {
    if (save) {
        if (save.status >= 200 && save.status < 300) {
            alert(t("exercices.save-success"))
            _this.$router.push("/videos")
        } else {
            alert(save.data.message)
        }
    } else {
        _this.previw = false
        alert(t("exercices.fill-error"))
        _this.showModal = true
    }
    
    _this.SET_LOADING(false)
    // })
}

export const play = async (_this) => {
    _this.playing = true
    await previwVideo(window.canvas, _this.speed, _this.saveFrame)
    _this.playing = false
    _this.frame = 1
    _this.loadFrame()
}
