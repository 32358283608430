import { t } from '@/lang'

export const DataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ia], { type: mimeString })
}

export const fillData = (formData, exercice) => {
    for(const key in exercice) {
        const optionals = ['category_id', 'category', 'img_url']
        if (optionals.includes(key) && !exercice[key]) {
            continue
        }
        if ((!exercice[key] || String(exercice[key]).trim().length <= 0) && !key.startsWith("h_")) {
            alert(key)
            return false
        }
        formData.append(key, exercice[key])
    }

    return true
}

export const deleteExercice = async (id, delMethod, path ='exercicios') => {
    const response = await delMethod({
        url: path + '/' + id
    })
    if (response.status >= 200 && response.status < 300) {
        alert(t('general.deleted'))
    } else {
        alert(response.data.message)
    }
}

export const updateExercice = async (data, putMethod) => {
    const response = await putMethod({
        url: 'exercicios/' + data.id,
        data: data
    })
    if (response.status >= 200 && response.status < 300) {
        alert(t("general.updated"))
    } else {
        alert(response.data.message)
    }
}

export const getExImage = async (id, getMethod, path = 'exercicioimg/', type = 'image/png') => {
    const response = await getMethod({url: path + id, type: 'blob'})
    if (response.status != 200) { 
        console.log(response)
        return null 
    }
    const url = URL.createObjectURL(new Blob([response.data], { type: type }))
    return url
}

export const decodeEntities = encodedString => {
    var parser = new DOMParser();
    var dom = parser.parseFromString('<!doctype html><body>' + encodedString, 'text/html');
    return dom.body.textContent;
}

// mais metodos
export const showInfo = (_this) => {
    _this.showModal = true
}

export const deleteHandler = _this => {
    if (window.canvas.getActiveObject()) {
        const obj = window.canvas.getActiveObject()
        _this.deleteObject(null, {target: obj})
    }
}

// change file
export const changeFile = (event, _this) => {
    if (event.target.files[0]) {
        _this.exercice.img = event.target.files[0]
        _this.img = URL.createObjectURL(event.target.files[0])
    }
}
