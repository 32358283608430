import { fabric } from "fabric"
// import { scale } from "./DrawFunctions"

const dataTransfer = {}

export const setDataTransfer = (key, val) => {
    dataTransfer[key] = val
}

export const drop = event => {
    const image = dataTransfer.image; 
    
    if (image) {
        fabric.Image.fromURL(image.src, function(fabricImg) {
            window.i = fabricImg
            if (!image.personal) {
                fabricImg.scale(129.12 / Math.max(fabricImg.width, fabricImg.height))
            }
            fabricImg.top = (960 * event.layerY / 472.5) - ((fabricImg.height / 2) * fabricImg.scaleY)
            fabricImg.left = (1280 * event.layerX / 630) - ((fabricImg.width / 2) * fabricImg.scaleX)

            window.canvas.add(fabricImg); 
        }, {
            objectCaching: false,
            preserveObjectStacking: true
        })
    }
    // clear dataTransfer
    for(const data in dataTransfer) {
        delete dataTransfer[data]
    }
}