<template>
    <div class="row p-0 m-0">
        <input type="file" @change="changeFile" class="d-none" ref="file">
        <div class="p-1 col-auto">
            <button class="btn btn-warning" @click="selectFile">
                <i class="fa fa-upload"></i> {{ t('general.upload-file') }}
            </button>
        </div>
        <button style="width: 70px; height: 70px;" class="button" v-for="(img, i) of imgs" :key="img">
            <img :src="img" :id="['personal-image-' + i]" draggable="true" class="img" @drag="drag" @click="dblclick">
        </button>
    </div>
</template>

<script>
    import { t } from '@/lang'
    import { setDataTransfer } from '@/utilities/DropFunctions.js'
    export default {
        name: 'PersonalComponent',
        data() {
            return {
                imgs: []
            }
        },
        emits: ['click'],
        methods: {
            t,
            drag(e) {
                e.target.personal = true
                setDataTransfer("image", e.target);
            },
            selectFile() {
                this.$refs.file.click()
            },
            changeFile(event) {
                const [img] =event.target.files
                if (img) {
                    this.imgs.push(URL.createObjectURL(img))
                }
            },
            async dblclick(e) {
                e.target.personal = true
                setDataTransfer("image", e.target);
                this.$emit('click', e.target)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .button {
        padding: 2px;
        background-color: transparent;
        border: none;
        margin: 0px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
</style>