<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('admin.plans') }}</h3>
            </div>
            <div class="p-1">
                <router-link class="btn btn-success btn-flat" to="/admin/users">
                    {{ t('admin.users-gerir') }}
                </router-link>
                <button class="btn btn-warning btn-flat" @click="() => this.newPlan.show = true">
                    <i class="fa fa-plus"></i> {{ t("datatable.add") }}
                </button>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <data-table v-if="plans" class="table table-striped table-bordered" :data="plans" :columns="columns"
                    :options="{responsive: true, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th>{{ t('auth.signup.name') }}</th>
                            <th>{{ t('payment.days-of-use') }}</th>
                            <th>{{ t('payment.price') }}</th>
                            <th>{{ t('payment.by') }}</th>
                            <th>{{ t('general.actions') }}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>

        <!-- Modals do plano -->
        <modal-component :classList="['col-10', 'col-sm-8', 'col-md-7', 'col-lg-5', 'col-xlg-5']" v-if="selected" :title="selected.title"
            @hide="() => {this.selected = null}"
            :show="true"
        >
            <form ref="form" @submit.prevent="update" class="row p-1 col-12">
                <div class="p-1 form-group m-0 py-0 col-12">
                    <span class="d-block">{{ t('payment.by') }}</span>
                    <div class="form-control">{{ selected.user.name }}</div>
                </div>
                <div class="p-1 form-group m-0 py-2 col-12" v-for="input in inputs" :key="input.model">
                    <label class="m-0" :for="input.model">{{ input.text }}</label>
                    <input :type="input.type" :step="input.step" :name="input.model" v-model="selected[input.model]" class="form-control" required>
                </div>
                <div class="p-1 form-group m-0 py-0 col-12">
                    <label for="short_desc">{{ t('treinos.description') }}</label>
                    <textarea v-model="selected.short_desc" class="form-control" maxlength="255"></textarea>
                </div>

                <div class="text-right p-1 py-2">
                    <button class="btn btn-primary">
                        {{ t('treinos.update') }}
                    </button>
                </div>
            </form>
        </modal-component>

        <modal-component :classList="['col-10', 'col-sm-8', 'col-md-7', 'col-lg-5', 'col-xlg-5']" v-if="newPlan.show" :title="t('payment.new')"
            @hide="() => {this.newPlan.show = false}"
            :show="true"
        >
            <form ref="form" @submit.prevent="create" class="row p-1 col-12">
                <div class="p-1 form-group m-0 py-2 col-12" v-for="input in inputs" :key="input.model">
                    <label class="m-0" :for="input.model">{{ input.text }}</label>
                    <input :type="input.type" :step="input.step" :name="input.model" v-model="newPlan[input.model]" class="form-control" required>
                </div>
                <div class="p-1 form-group m-0 py-0 col-12">
                    <label for="short_desc">{{ t('treinos.description') }}</label>
                    <textarea v-model="newPlan.short_desc" class="form-control" maxlength="255"></textarea>
                </div>

                <div class="text-right p-1 py-2">
                    <button class="btn btn-primary">
                        {{ t('treinos.create') }}
                    </button>
                </div>
            </form>
        </modal-component>
        <!-- ./Modals do plano -->
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { decodeEntities } from '@/utilities/HelpFunctions.js'
    import { t } from '@/lang'
    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "PlansView",
        components: {
            DataTable,
            ModalComponent,
        },
        data() {
            return {
                plans: null,
                selected: null,
                user: null,
                newPlan: {
                    title: '',
                    days: 30,
                    price: 0,
                    short_desc: '',
                    show: false,
                },
                columns: [
                    {data: 'id'},
                    {data: 'title'},
                    {data: 'days'},
                    {data: 'price'},
                    {data: null, render: data => data.user.name},
                    {data: null, render: data => `
                        <span class='p-1' ${this.user.level != "master" ? "style='display: none;'":""}>
                            <button class='btn btn-warning btn-flat' data-id='${data.id}' data-action='edit'>
                                <i class='fa fa-edit' data-id='${data.id}' data-action='edit'></i>
                            </button>
                        </span>
                        <span class='p-1' ${this.user.level != "master" ? "style='display: none;'":""}>
                            <button class='btn btn-danger btn-flat' data-id='${data.id}' data-action='delete'>
                                <i class='fa fa-trash' data-id='${data.id}' data-action='delete'></i>
                            </button>
                        </span>
                    `}
                ],
                inputs: [
                    {model: 'title', text: t('auth.signup.name'), type: 'text' },
                    {model: 'days', text: t('payment.days-of-use'), type: 'number' },
                    {model: 'price', text: t('payment.price'), type: 'number', step: 0.01 },
                ]
            }
        },
        created() {
            document.title = t('admin.plans')
            this.getUser();
            this.getPlans()
        },
        
        computed: {
            ...mapState(['loading'])
        },
        methods: {
            ...mapActions(['get', 'post', 'put', 'delete']),
            ...mapMutations(['SET_LOADING']),
            async handleClick(event) {
                if (event.target.hasAttribute('data-action')) {
                    const planID = event.target.getAttribute('data-id')
                    let plan = null
                    switch(event.target.getAttribute('data-action')) {
                        case 'edit':
                            [plan] = this.plans.filter(plan => plan.id == planID)
                            this.selected = Object.assign({}, plan)
                            for(const key in this.selected) {
                                if (key == 'user') { continue }
                                this.selected[key] = decodeEntities(this.selected[key])
                            }
                            break
                        case 'delete':
                            this.selected = null
                            if (confirm(t('general.do-you-want'))) {
                                await this.deletePlan(planID)
                            }
                            break
                    }
                }
            },
            async getPlans() {
                this.SET_LOADING(true)
                const response = await this.get({url: 'plans'})
                this.plans = response.data.plans
                
                if (response.status != 200) {
                    alert(response.data.message)
                    location = '/profile'
                }
                this.SET_LOADING(false)

                if (!this.loading) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }
            },
            async update() {
                this.SET_LOADING(true)
                const response = await this.put({
                    url: 'admin/plans/' + this.selected.id,
                    data: this.selected
                })

                if (response.status == 200) {
                    alert(t("general.updated"))
                } else {
                    alert(response.data.status)
                }
                this.selected = null
                this.SET_LOADING(false)
                this.getPlans()
            },
            async deletePlan(id) {
                this.SET_LOADING(true)
                const response = await this.delete({
                    url: 'admin/plans/' + id
                })

                if (response.status == 200) {
                    alert(t("general.deleted"))
                } else {
                    alert(response.data.status)
                }
                this.selected = null
                this.SET_LOADING(false)
                this.getPlans()
            },
            async create() {
                this.SET_LOADING(true)
                const response = await this.post({
                    url: 'admin/plans/',
                    data: this.newPlan
                })

                if (response.status == 200) {
                    alert(t("payment.created"))
                } else {
                    alert(response.data.message)
                }
                this.newPlan.show = null
                this.SET_LOADING(false)
                this.getPlans()
            },
            async getUser() {
                this.SET_LOADING(true)
                const response = await this.get({url: 'user'})
                if (response.status == 200) {
                    this.user = response.data.user
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)
            },
            t
        }  
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
</style>
